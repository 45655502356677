import React from 'react';
import { IonIcon, IonInput, IonButton, IonLabel, IonItem, IonAlert } from '@ionic/react';
import './index.css';
import { FieldError, NormalText, Spacer, StrongText, Title } from '../common';
import { getTransactionHistory, redeemGiftVoucher } from '../../store/actions';
import { connect } from 'react-redux';
import { CLEAR_REDEEMED_GIFT_VOUCHER } from '../../store/constants';
import Modal from '../modal';
import successIcon from '../../assets/images/success-icon.png';
import Basket from '../../lib/basket';

class RedeemGiftVoucher extends React.Component {
  state = {
    reedemGiftCode: '',
    showSuccess: false,
    redeemedGiftVoucher: null,
  };

  redeemGiftVoucher = () => {
    const { dispatch } = this.props;
    const { reedemGiftCode } = this.state;
    dispatch(redeemGiftVoucher({ code: reedemGiftCode }));
    dispatch(getTransactionHistory());
    this.setState({ reedemGiftCode: '' });
  };
  componentDidMount() {
    const { dispatch } = this.props;
    if (this.props.redeemedGiftVoucher) {
      this.setState(
        { redeemedGiftVoucher: this.props.redeemedGiftVoucher, showSuccess: true },
        () => {
          dispatch({ type: CLEAR_REDEEMED_GIFT_VOUCHER });
        },
      );
    }
  }
  componentDidUpdate() {
    const { dispatch } = this.props;
    if (this.props.redeemedGiftVoucher) {
      this.setState(
        { redeemedGiftVoucher: this.props.redeemedGiftVoucher, showSuccess: true },
        () => {
          dispatch({ type: CLEAR_REDEEMED_GIFT_VOUCHER });
        },
      );
    }
  }
  render() {
    // const { show, value } = this.state;
    const { __ } = this.props;
    const { redeemedGiftVoucher } = this.state;
    return (
      <>
        <div className="redeem-gift-voucher-component-wrapper">
          <div className="input-field-container">
            <NormalText>{__('Enter Voucher Code')}</NormalText>
            <IonItem lines="none" className="input-field-wrapper">
              <IonInput
                onIonInput={(e) => this.setState({ reedemGiftCode: e.target.value })}
                value={this.state.reedemGiftCode}
                placeholder={__('Enter your gift code')}
              ></IonInput>
            </IonItem>
          </div>
          <IonButton onClick={() => this.redeemGiftVoucher()} color="secondary">
            {__('Redeem')}
          </IonButton>
        </div>
        {this.state.showSuccess && (
          <Modal
            isOpen={this.state.showSuccess}
            onDidDismiss={() => {
              this.setState({ showSuccess: false });
              this.props.dispatch({ type: CLEAR_REDEEMED_GIFT_VOUCHER });
            }}
          >
            <div className="success-gift-voucher centered">
              <Title className="default-padding centered">{__('Success')}</Title>
              <Spacer />
              <img src={successIcon}></img>
              <Spacer />
              {redeemedGiftVoucher && (
                <>
                  <NormalText>
                    `
                    <StrongText>
                      {redeemedGiftVoucher.points_value} {__('points')}
                    </StrongText>{' '}
                    {__('have been added to your loyalty balance worth')}{' '}
                    <StrongText>
                      {Basket.formatPrice(redeemedGiftVoucher.points_value / 100)}
                    </StrongText>
                    `
                  </NormalText>
                  {redeemedGiftVoucher.personal_message !== '' && (
                    <>
                      <Spacer />
                      <NormalText>{__('The sender attached the following message')}:</NormalText>
                      <Spacer />
                      <NormalText>{redeemedGiftVoucher.personal_message}</NormalText>
                      <Spacer />
                    </>
                  )}
                </>
              )}
            </div>
          </Modal>
        )}
      </>
    );
  }
}

const stateToProps = (state) => {
  return {
    redeemedGiftVoucher: state.orders.redeemedGiftVoucher || null,
  };
};

export default connect(stateToProps)(RedeemGiftVoucher);
