import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Account from './screens/account';
import ReferAFriend from './screens/referAFriend';
import Feedback from './screens/feedback';
import AppSupport from './screens/appSupport';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Inbox from './screens/inbox';
import Notification from './screens/inbox/singleNotification';

import Privacy from './screens/privacy';
import Faq from './screens/faq';
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import PickUpPoint from './screens/pick-up-point';
import PickUpPointCheck from './screens/pick-up-point-check';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import OrderToTable from './screens/orderToTable';
import ApplyVouchers from './screens/applyVouchers';
import ApplyAllergens from './screens/allergens';
import { ServiceCharge } from './screens/service-charge';
import GiftVouchers from './screens/giftVouchers';

import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import ApplyLoyalty from './screens/applyLoyalty';
import CreateAccount from './screens/createAccount';
import api from './lib/api';
const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;

import home from './assets/images/home-icon.svg';
import loyalty from './assets/images/icons/loyalty.svg';
import myAccount from './assets/images/icons/account.svg';
import feedback from './assets/images/icons/feedback.svg';
import history from './assets/images/icons/history.svg';
import restaurants from './assets/images/icons/locations.svg';
import social from './assets/images/icons/social-media.svg';
import order from './assets/images/icons/order.svg';
import gift from './assets/images/icons/gift.svg';

import login from './assets/images/icons/login.svg';
import logout from './assets/images/icons/logout.svg';
import settings from './assets/images/settings-icon.svg';
import referAFriend from './assets/images/icons/refer.svg';

import qm from './assets/images/icons/qm.svg';
import menu from './assets/images/icons/menu.svg';
import inbox from './assets/images/icons/inbox.svg';

const { appType } = getConfig();
const { hasOrdering, hasGiftVouchers } = appType;
let navConfig;
export const initRouter = async () => {
  const config = await api.getFrontEndAppConfig();
  const singleDeliveryOption = getSingleDeliveryOption();
  const { hasReferAFriend } = config.flags;
  navConfig = {
    routes: [
      {
        label: 'Loyalty',
        path: '/loyalty',
        component: Loyalty,
        icon: loyalty,
        protected: true,
        default: isWebConfig(),
        group: 1,
      },
      {
        label: 'Home',
        path: '/dashboard',
        component: Dashboard,
        icon: home,
        exact: true,
        default: true,
        notInDrawer: true,
      },
      {
        label: 'Apply Loyalty',
        path: '/apply-loyalty',
        component: ApplyLoyalty,
        icon: menu,
        protected: true,
        notInDrawer: true,
        group: 1,
      },

      ...(hasGiftVouchers
        ? [
            {
              label: 'Gift Vouchers',
              path: '/gift-vouchers',
              component: GiftVouchers,
              icon: gift,
              protected: false,
              group: 1,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Start New Order',
              path: '/delivery-options',
              component: DeliveryOptions,
              icon: order,
              protected: false,
              notInDrawer: !!singleDeliveryOption,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: singleDeliveryOption ? singleDeliveryOption.label : '',
              path: '/click-and-collect',
              component: ClickAndCollect,
              icon: settings,
              protected: false,
              notInDrawer: !singleDeliveryOption,
            },
            {
              label: singleDeliveryOption ? singleDeliveryOption.label : '',
              path: '/order-to-table',
              component: OrderToTable,
              protected: false,
              notInDrawer: true,
            },
          ]
        : []),

      ...(hasReferAFriend
        ? [
            {
              label: 'Refer a Friend',
              path: '/refer-a-friend',
              component: ReferAFriend,
              icon: referAFriend,
              protected: true,
              group: 1,
            },
          ]
        : []),
      {
        label: 'History',
        path: '/history',
        component: History,
        icon: history,
        protected: true,
        group: 3,
      },
      {
        label: 'My Account',
        path: '/account',
        component: Account,
        icon: myAccount,
        protected: true,
        group: 3,
      },

      ...(hasOrdering
        ? [
            {
              label: 'Checkout',
              path: '/checkout',
              component: Checkout,
              icon: settings,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Apply Points',
              path: '/apply-points',
              component: ApplyPoints,
              icon: settings,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Apply Points',
              path: '/allergens',
              component: ApplyAllergens,
              icon: settings,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Service Charge',
              path: '/service-charge',
              component: ServiceCharge,
              notInDrawer: true,
              protected: false,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Apply Points',
              path: '/allergens',
              component: ApplyAllergens,
              icon: settings,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),

      {
        label: 'Leave Feedback',
        path: '/feedback',
        component: Feedback,
        icon: feedback,
        protected: true,
        group: 3,
      },
      {
        label: 'App Support',
        path: '/app-support',
        component: AppSupport,
        icon: qm,
        protected: true,
        group: 3,
      },
      {
        label: 'Locations',
        path: '/locations',
        component: Locations,
        icon: restaurants,
        protected: false,
        group: 4,
      },
      {
        label: 'Social Media',
        path: '/social',
        component: Social,
        icon: social,
        protected: false,
        group: 4,
      },
      {
        label: 'FAQs',
        path: '/faq',
        component: Faq,
        icon: qm,
        protected: false,
        group: 4,
      },
      {
        label: 'Terms & Conditions',
        path: '/terms',
        icon: qm,
        group: 4,
        protected: false,
        component: Terms,
      },
      {
        label: 'Inbox',
        path: '/inbox',
        icon: inbox,
        group: 4,
        protected: true,
        component: Inbox,
      },
      {
        label: 'Notification',
        path: '/notification',
        icon: inbox,
        group: 4,
        protected: true,
        component: Notification,
        notInDrawer:true
      },
      {
        label: 'Contact Details',
        path: '/contact-details',
        component: ContactDetails,
        protected: true,
        notInDrawer: true,
      },
      {
        label: 'Apply Vouchers',
        path: '/apply-vouchers',
        component: ApplyVouchers,
        notInDrawer: true,
        protected: true,
      },
    ],
    authRoutes: [
      { label: 'Login', path: '/login', component: Login, icon: logout, fn: 'login' },
      { label: 'Logout', path: '/logout', icon: login, fn: 'logout' },
      { path: '/contact-details', component: ContactDetails },
    ],
    additionalRoutes: [, { label: 'Privacy Policy', path: '/privacy', component: Privacy }],
    notInMenuRoutes: [
      { path: '/register', component: Register },
      { path: '/reset-password', component: ResetPassword },
      { path: '/create-account', component: CreateAccount },

      ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
      ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
      ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
      ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
      ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
      ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
      ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
      ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
      ...(hasOrdering
        ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }]
        : []),
      ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
      ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
      ...(hasOrdering ? [{ path: '/pick-up-point', component: PickUpPoint }] : []),
      ...(hasOrdering ? [{ path: '/pick-up-point-check', component: PickUpPointCheck }] : []),
    ],
  };
  return navConfig;
};

export default navConfig;
