import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import HistoryTab from './historyTab';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { getTransactionHistory } from '../../store/actions';
import SwipableTabs from '../../components/swipeableTabs';
import { getConfig } from '../../appConfig';
import './index.css';
import moment from 'moment';
import basket from '../../lib/basket';

const tabLabelMap = {
  ordering: {
    first: 'Loyalty',
    second: 'Orders',
  },
  catalog: {
    first: 'Accrue',
    second: 'Redeem',
  },
};

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getTransactionHistory();
  }

  getTransactionHistory = () => {
    this.props.dispatch(getTransactionHistory());
  };
  getLabel(item) {
    let label = '';
    if (`${item.business_location_id}` == '-1') {
      label = 'Referral Bonus';
    } else if (`${item.business_location_id}` == '-2') {
      label = 'Sign up Bonus';
    } else if (`${item.business_location_id}` == '-3') {
      label = 'Refunded points';
    } else if (`${item.business_location_id}` == '-4') {
      label = 'Loyalty tier renewed';
    } else if (`${item.business_location_id}` == '-5') {
      label = 'Loyalty tier decreased';
    } else if (`${item.business_location_id}` == '-6') {
      label = 'Loyalty tier increased';
    } else if (`${item.business_location_id}` == '-7') {
      label = 'Loyalty balance transfer';
    } else if (item.stamp_power < 0) {
      label = 'Redeemed Points';
    } else if (item.stamp_power > 0) {
      label = 'Earned Points';
    }
    return label;
  }
  getNameOfLabelFirstTab = () => {
    const { hasOrdering } = getConfig().appType;
    const { __, transactionHistory } = this.props;
    const firstTabListAdditional = transactionHistory.map((item) => ({
      ...item,
      label: this.getLabel(item),
    }));

    return firstTabListAdditional;
  };

  render() {
    const { __, transactionHistory, orderHistory } = this.props;
    const { hasOrdering, hasLoyalty } = getConfig().appType;
    const tabLabels = hasOrdering ? tabLabelMap.ordering : tabLabelMap.catalog;
    const secondTabList = hasOrdering
      ? orderHistory.map((i) => ({
          label: i.restaurant_name + ' #' + i.id,
          transaction_date: !i.is_gift
            ? i.eat_in
              ? moment(i.created_at).format('DD MMMM YYYY [at] h:mm a')
              : basket.getDate(i.collection_time).format('DD MMMM YYYY [at] h:mm a')
            : basket.getDate(i.collection_time).format('DD MMMM YYYY'),
          item: i,
        }))
      : transactionHistory
          .filter((i) => i.stamp_power < 0)
          .map((item) => ({ ...item, label: 'Redeemed' }));

    const firstTabType = 'points';
    const secondTabType = hasOrdering ? 'order' : 'points';
    return (
      <Layout
        headerWithTitle={true}
        headerTitle={__('Your History')}
        hideSecondToolbar={true}
        noPadding={true}
        color="transparent"
        scrollY={false}
      >
        {hasLoyalty && hasOrdering ? (
          <SwipableTabs
            history={this.props.location}
            tabs={[
              {
                label: __(tabLabels.first),
                tabContent: (
                  <HistoryTab
                    type={firstTabType}
                    transactionHistory={this.getNameOfLabelFirstTab()}
                    getTransactionHistory={this.getTransactionHistory}
                    restaurants={this.props.restaurants}
                    dispatch={this.props.dispatch}
                  />
                ),
              },
              {
                label: __(tabLabels.second),
                tabContent: (
                  <HistoryTab
                    type={secondTabType}
                    transactionHistory={secondTabList}
                    getTransactionHistory={this.getTransactionHistory}
                    restaurants={this.props.restaurants}
                    dispatch={this.props.dispatch}
                  />
                ),
              },
            ]}
          />
        ) : hasLoyalty ? (
          <HistoryTab
            type={firstTabType}
            transactionHistory={this.getNameOfLabelFirstTab()}
            getTransactionHistory={this.getTransactionHistory}
            restaurants={this.props.restaurants}
            dispatch={this.props.dispatch}
          />
        ) : hasOrdering ? (
          <HistoryTab
            type={secondTabType}
            transactionHistory={secondTabList}
            getTransactionHistory={this.getTransactionHistory}
            restaurants={this.props.restaurants}
            dispatch={this.props.dispatch}
          />
        ) : null}
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  return {
    restaurants: state.restaurants || [],
    transactionHistory: state.orders.history || [],
    orderHistory: state.orders.orderHistory || [],
    restaurants: state.restaurants.restaurants || [],
  };
};

export default withRouter(withTranslation(connect(stateToProps)(History)));
