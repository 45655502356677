import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';
import { getNotifications, readNotifications } from '../../store/actions';
import Loading from '../../components/spinner';
import './index.css';
import { NormalText, SmallText, Spacer, Subtitle, Title } from '../../components/common';
import moment from 'moment';
import { IonButton } from '@ionic/react';
import { forwardTo } from '../../lib/utils';

class Notification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notification: this.props.location?.state?.notification || {}
        };

    }
    componentDidMount() {

    }
    openCTALink(url) {
        if (url.includes('https://') || url.includes('http://')) {
            window.open(url)
        } else {
            forwardTo(url)
        }
    }
    render() {
        const { __ } = this.props;
        const { notification } = this.state
        return (
            <Loading>
                <Layout
                    hideBack={true}
                    hideSecondToolbar={true}
                    color="transparent"
                    headerTitle={__('Inbox')}


                >
                    <div className='scrollable-y absolute-content'>
                        {notification.show_image && notification.image &&
                            <img className='notification-cover-image' src={notification.image} />
                        }
                        <div className='notification-body'>
                            <Title>{notification.notification_title}</Title>
                            <Spacer size={1} />
                            <div></div>
                            <div className='notification-html-content' dangerouslySetInnerHTML={{ __html: notification.content_html }} ></div>
                            <Spacer size={2} />
                            {notification.show_cta &&
                                <IonButton onClick={() => this.openCTALink(notification.cta_url)} expand='block' color='primary'>{notification.cta_button_text}</IonButton>

                            }


                        </div>
                    </div>
                </Layout>
            </Loading>
        );
    }
}

const stateToProps = (state) => {
    return {}
};

export default connect(stateToProps)(withTranslation(Notification));
