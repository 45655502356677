import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';
import { readNotifications } from '../../store/actions';
import Loading from '../../components/spinner';
import './index.css';
import { NormalText, Subtitle, Title } from '../../components/common';
import moment from 'moment';
import { forwardTo } from '../../lib/utils';
import { IonButton } from '@ionic/react';
import { getConfig } from '../../appConfig';
import NoDataSecond from '../../components/noDataSecond';

class Inbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandNotificationBody: false
    };

  }

  componentDidMount() {
    this.props.dispatch(readNotifications());
  }

  render() {
    const { __, notifications } = this.props;
    const { expandNotificationBody } = this.state
    return (
      <Loading>
        <Layout
          hideBack={true}
          hideSecondToolbar={true}
          color="transparent"
          headerTitle={__('Inbox')}
        >
          <div className='scrollable-y absolute-content'>

            {notifications.length == 0 ?
              <NoDataSecond label="Looks like you don't have any notifications." />

              : <Title>{__('Inbox')}</Title>

            }
            {notifications?.map((el, index) => (

              <div className='notification-wrapper box-wrapper' onClick={() => getConfig().openNotification ? forwardTo('/notification', { notification: el }) : this.setState({ expandNotificationBody: this.state.expandNotificationBody === index ? false : index })}>
                <div className='notification-header'>
                  <div className='notification-header-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" /><path d="M3 7l9 6l9 -6" /></svg>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Subtitle>{el.notification_title}</Subtitle>
                    <NormalText className='color-light'>{moment(el.created_at).format('DD MMM, YYYY - h:mm A')}</NormalText>
                  </div>
                  {moment().diff(moment(el.created_at), 'hours') <= 24 &&
                    <div className='notification-status'>{__('NEW')}</div>

                  }

                </div>
                <div className='notification-content'>
                  {getConfig().openNotification ? <>
                    <NormalText className='block notification-preview'>{el.notification_body}</NormalText>
                  </> : <>

                    {el.notification_body.length < 100 ? <>
                      <NormalText className='block'>{el.notification_body}</NormalText>
                    </> : <>

                      {(expandNotificationBody === index) ?
                        <NormalText className='block'>{el.notification_body}</NormalText>
                        :
                        <>
                          <NormalText className='block'>{el.notification_body.slice(0, 100)}...</NormalText>
                        </>
                      }
                      <IonButton className='link underlined small-btn'>{(expandNotificationBody === index) ? __('Show less') : __('Show more')}</IonButton>

                    </>}

                  </>}
                </div>
              </div>
            ))}
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    notifications: state.profile.notifications || [],
  };
};

export default connect(stateToProps)(withTranslation(Inbox));
