import React from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonTextarea,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonAlert,
  IonCheckbox,
} from '@ionic/react';
import { withRouter } from 'react-router';
import { starOutline, star } from 'ionicons/icons';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { sendFeedback, setCommonModal } from '../../store/actions';
import {
  FieldError,
  Title,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
  Subtitle,
} from '../../components/common';
import { validateForm, isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import './index.css';

const { SelectOption } = Mobiscroll;

class AppSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.resetState(),
    };
    this.formConfig = {
      food: { type: 'integer', required: true, invalidValue: 0 },
      service: { type: 'integer', required: true, invalidValue: 0 },
      selectedRestaurant: { type: 'select', required: true, invalidValue: null },
    };
  }

  resetState = () => ({
    visit: 0,
    service: 0,
    venue: 0,
    cleanliness: 0,
    decor: 0,
    location: 0,
    customerService: false,
    techSupport: true,
    commentService: '',
    commentTech: '',
    selectedRestaurant: null,
    formErrors: {},
  });

  createStarSet(size, name) {
    return <>{[...Array(size).keys()].map((i) => this.createStar(name, i + 1))}</>;
  }

  createStar = (name, value) => {
    if (this.state[name] >= value) {
      return (
        <IonIcon
          key={value}
          icon={star}
          color="secondary"
          className="star"
          onClick={() => this.setValue(name, value)}
        />
      );
    } else {
      return (
        <IonIcon
          key={value}
          icon={starOutline}
          className="star"
          onClick={() => this.setValue(name, value)}
        />
      );
    }
  };

  handleFeedback = () => {
    let formErrors;
    if (this.state.customerService) {
      formErrors = validateForm(this.formConfig, this.state);
    } else {
      formErrors = [];
    }
    this.setState({ formErrors });

    if (Object.keys(formErrors).length === 0) {
      const {
        visit,
        service,
        venue,
        cleanliness,
        decor,
        location,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
      } = this.state;
      const feedback = {
        visit,
        service,
        venue,
        cleanliness,
        decor,
        location,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
      };
      this.props.dispatch(sendFeedback(feedback));
      // reset state
      this.setState({ ...this.resetState() });
    }
  };
  setCheckboxValue(field, value) {
    const decheckedKey = field === 'customerService' ? 'techSupport' : 'customerService';
    this.setState({ [field]: value, [decheckedKey]: !value });
  }
  selectRestaurant = (event, data) => {
    const selectedRestaurantId = data.getVal();
    this.setState({ selectedRestaurant: selectedRestaurantId });
    if (selectedRestaurantId !== null) {
      let formErrors = { ...this.state.formErrors };
      formErrors.selectedRestaurant = null;
      this.setState({ formErrors });
    }
  };

  setValue(field, value) {
    this.setState({ [field]: value });
  }

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (isDefined(store.is_published)) {
        if (store.is_published) {
          arrForSelect.push({ text: store.name, value: store.id });
        }
      }
    });
    return [{ text: __('Select Restaurant'), value: null }, ...arrForSelect];
  };

  handleChange = (event) => {
    this.setValue(event.detail.value, true);
    this.setState({
      radioGroup: event.detail.value,
    });
  };

  render() {
    const { __, isFeedbackModalOpen, restaurants } = this.props;
    const { techSupport, commentService, commentTech, formErrors, customerService } = this.state;
    const stores = restaurants.sort((a, b) => {
      if (isDefined(a.name) && isDefined(b.name)) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
      return 0;
    });

    return (
      <Loading transparent>
        <Layout color="transparent" hideSecondToolbar={true} headerTitle={__('Feedback')}>
          <div>
            <div className="heading-wrapper">
              <Title>{__('Leave Feedback')}</Title>
            </div>
            <div className="frm-wrapper">
              <div className="box-holder box-holder--feedback box-holder-top input-field-wrapper">
                <IonList>
                  <div>
                    <IonItem>
                      <div tabIndex="-1"></div>

                      <IonLabel>
                        <Subtitle className="">{__('Customer Service')}</Subtitle>
                        <IonLabel className="ion-text-wrap">
                          <NormalText color="primary">
                            {__('Any comments related to your visit')}
                          </NormalText>
                        </IonLabel>
                      </IonLabel>
                      <IonCheckbox
                        slot="start"
                        color="primary"
                        className="top-small"
                        onIonChange={(e) =>
                          this.setCheckboxValue('customerService', e.detail.checked)
                        }
                        checked={customerService}
                      />
                    </IonItem>
                    <IonItem lines="none">
                      <div tabIndex="-1"></div>
                      <IonLabel>
                        <Subtitle className="">{__('Tech Support')}</Subtitle>
                        <IonLabel className="ion-text-wrap">
                          <NormalText color="primary">
                            {__('Any technical help using our app')}
                          </NormalText>
                        </IonLabel>
                      </IonLabel>
                      <IonCheckbox
                        slot="start"
                        color="primary"
                        className="top-small"
                        onIonChange={(e) => this.setCheckboxValue('techSupport', e.detail.checked)}
                        checked={techSupport}
                      />
                    </IonItem>
                  </div>
                </IonList>
              </div>
              {techSupport ? (
                <div className="">
                  <Spacer size={1} />

                  <div className="feedback-box-wrapper input-field-container">
                    <NormalText className="feedback-label ">{__('Comments')}:</NormalText>
                    <div className="input-field-wrapper">
                      <IonTextarea
                        className="thiner-text"
                        value={commentTech}
                        onIonChange={(event) => this.setValue('commentTech', event.detail.value)}
                      ></IonTextarea>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Spacer size={1} />
                  <div className="feedback-box-wrapper input-field-container">
                    <NormalText className="feedback-label ">{__('Comments')}:</NormalText>
                    <div className="commentService input-field-wrapper">
                      <IonTextarea
                        className="thiner-text"
                        value={commentService}
                        onIonChange={(event) => this.setValue('commentService', event.detail.value)}
                      ></IonTextarea>
                    </div>
                  </div>
                  <div className="input-field-container">
                    <NormalText>{__('Location')}</NormalText>
                    <IonItem lines="none" className="input-field-wrapper dropdown-field">
                      <SelectOption
                        className="select-picker-input--feedback"
                        display="center"
                        onSet={(e, a) => this.selectRestaurant(e, a)}
                        data={this.formatDataForSelect(stores)}
                        label="Location"
                        value={this.state.selectedRestaurant}
                        inputStyle="box"
                        placeholder={__('Select Restaurant')}
                        setText={__('OK')}
                        cancelText={__('Cancel')}
                      />
                    </IonItem>
                  </div>
                  <Spacer size={1} />
                  {formErrors.selectedRestaurant ? (
                    <FieldError
                      className="field-error pad10"
                      value={__(formErrors.selectedRestaurant)}
                    />
                  ) : null}
                  <div className="box-holder box-holder--feedback  pad10 input-field-wrapper">
                    <div className="inline-input">
                      <StrongText className="feedback-label ">{__('Rate your visit')}</StrongText>
                      <div className="flex-col-holder righted">
                        {this.createStarSet(5, 'visit')}
                      </div>
                    </div>
                    <div className="inline-input inline-input--feedback">
                      <StrongText className="feedback-label ">{__('Rate our service')}</StrongText>
                      <div className="flex-col-holder righted">
                        {this.createStarSet(5, 'service')}
                      </div>
                    </div>
                    <div className="inline-input inline-input--feedback">
                      <StrongText className="feedback-label ">{__('Rate our venue')}</StrongText>
                      <div className="flex-col-holder righted">
                        {this.createStarSet(5, 'venue')}
                      </div>
                    </div>
                    <div className="inline-input inline-input--feedback">
                      <StrongText className="feedback-label ">
                        {__('Rate our cleanliness')}
                      </StrongText>
                      <div className="flex-col-holder righted">
                        {this.createStarSet(5, 'cleanliness')}
                      </div>
                    </div>
                    <div className="inline-input inline-input--feedback">
                      <StrongText className="feedback-label ">{__('Rate our decor')}</StrongText>
                      <div className="flex-col-holder righted">
                        {this.createStarSet(5, 'decor')}
                      </div>
                    </div>
                    <div className="inline-input inline-input--feedback">
                      <StrongText className="feedback-label ">{__('Rate our location')}</StrongText>
                      <div className="flex-col-holder righted">
                        {this.createStarSet(5, 'location')}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <Spacer size={1} />
            {techSupport && (
              <NormalText className="block">
                {__('You will receive a response within 24 hours.')}
              </NormalText>
            )}
            <Spacer size={1} />

            <div className="action-wrapper">
              <IonButton
                expand="block"
                className="uppercase"
                color="primary"
                onClick={this.handleFeedback}
              >
                {__('Submit')}
              </IonButton>
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={isFeedbackModalOpen}
          onDidDismiss={() => this.props.dispatch(setCommonModal('isFeedbackModalOpen', false))}
          header={__('Thank you')}
          // message={ __('Feedback is processed.') }
          message={__('We really appreciate you taking the time to let us know how we did.')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                this.props.dispatch(setCommonModal(('isFeedbackModalOpen', false)));
                this.setState({
                  visit: 0,
                  service: 0,
                  venue: 0,
                  cleanliness: 0,
                  decor: 0,
                  location: 0,
                  customerService: true,
                  techSupport: false,
                  commentService: '',
                  commentTech: '',
                });
              },
            },
          ]}
        />
      </Loading>
    );
  }
}
const stateToProps = (store) => {
  const { appVersion, isFeedbackModalOpen } = store.common;
  const { restaurants } = store.restaurants;
  return {
    appVersion,
    isFeedbackModalOpen,
    restaurants: restaurants || [],
  };
};

export default connect(stateToProps)(withRouter(withTranslation(AppSupport)));
