import React, { Component } from 'react';
import { IonList, IonListHeader, IonLabel, IonButton } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined } from '../../lib/utils';
import NoData from '../../components/noData';
import Basket from '../../lib/basket';
import { connect } from 'react-redux';
import { NormalText, SmallText, Spacer, StrongText, Title } from '../../components/common';
import Modal from '../../components/modal';

class orderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calc: false,
      isChooseSizeModalOpen: false,
      selectedItem: null,
    };
    this.ionList = null;
    this.orderListItems = null;
  }
  componentDidMount() {
    setTimeout(() => {
      const item = document.querySelector('.order-list-items');
      const itemList = document.querySelector('.order-list-items > ion-list');
      if (item && itemList) {
        this.ionList = itemList;
        // this.orderListItems = item
        if (this.ionList && this.orderListItems) {
          this.setState({ calc: true });
        }
        item.scrollTop = this.props.scrollTopPosition;
        // item.scrollBy({ top: 1, behavior: 'smooth' })
      }
    }, 500);
  }
  selectItem = (data) => {
    const { large } = data;
    const item = JSON.parse(JSON.stringify(this.state.selectedItem));
    if (large) {
      item.sku = item.largeSku;
      item.productPrice = item.largePrice;
    }
    this.props.showModal(item);
    this.setState({ isChooseSizeModalOpen: false, selectItem: null });
  };

  itemList = (__, subCategoryItems, i) => {
    const { profile, showModal } = this.props;
    return (subCategoryItems || []).map((item, index) => {
      if (item.sku === undefined || !Basket.isProductJustEnabled(item)) {
        return <></>;
      }

      let image =
        item.itemRichData && item.itemRichData.squareImageUrl
          ? item.itemRichData.squareImageUrl
          : '';
      if (image && image.indexOf('http://') !== -1) {
        image = image.replace(/http:\/\//g, 'https://');
      }
      const name = __(Basket.getProductName(item, profile));
      return (
        <div
          key={item.sku + '_' + index + i}
          title={name}
          className={`order-list-item  ${
            !Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''
          } `}
          onClick={() =>
            Basket.isProductUnsnoozed(item)
              ? item.hasLarge
                ? this.setState({ isChooseSizeModalOpen: true, selectedItem: item })
                : showModal(item)
              : null
          }
        >
          <div className="item-image">
            <div
              className="item-image-bg"
              style={image && image !== '' ? { backgroundImage: 'url(' + image + ')' } : {}}
            />
          </div>
          <div className="item-bar">
            <div className="item-name ellipsis">
              <SmallText className="">{name}</SmallText>
            </div>
            <div className="item-price">
              <SmallText className=" item-price">{Basket.formatPrice(item.productPrice)}</SmallText>
              {item.originalPrice ? (
                <>
                  <br />
                  <StrongText className=" original-price">&#163;{item.originalPrice}</StrongText>
                </>
              ) : null}
            </div>
          </div>
        </div>
      );
    });
  };

  drawCategoryItems = (
    __,
    category,
    items,
    breadCrumb = null,
    i,
    isGroup = false,
    allGroup = false,
  ) => {
    let name = category.name ? breadCrumb + category.name : breadCrumb + '';
    let drawSubCategory = isGroup ? (
      <div className="order-sublist-holder" ref={this.props.menuRefs[`${name}_${i}`]}>
        <IonListHeader className={allGroup ? 'order-sublist-header' : 'order-sublist-header empty'}>
          <IonLabel>{name}</IonLabel>
        </IonListHeader>
        <div className="order-list-grid">
          {this.subCategoriesList(__, category, breadCrumb + category.name)}
        </div>
      </div>
    ) : (
      <>{items}</>
    );
    return <React.Fragment key={category.type + '_' + name + i}>{drawSubCategory}</React.Fragment>;
  };

  breadCrumb = (name) => {
    let breadCrumb = name !== '' ? name + ' | ' : name;
    return breadCrumb;
  };

  subCategoriesList = (__, category, name) => {
    if (!isDefined(category)) {
      return <NoData />;
    }
    const categoryItems = category.menuEntry || category.menuEntryGroups || null;
    let items = this.itemList(__, categoryItems);
    let breadCrumb = this.breadCrumb(name);
    if (categoryItems) {
      return categoryItems
        .sort((a, b) => (a.type > b.type ? -1 : 1))
        .map((subCategory, index) => {
          if (subCategory['@type'] === 'group') {
            items = this.itemList(__, subCategory.menuEntry, index);
            const allGroup = subCategory.menuEntry.some((item) => item.type !== 'group');
            return this.drawCategoryItems(
              __,
              subCategory,
              items,
              breadCrumb,
              index,
              true,
              allGroup,
            );
          } else {
            items = this.itemList(__, [subCategory], index);
            return this.drawCategoryItems(__, subCategory, items, name, index, false);
          }
        });
    }
    return this.drawCategoryItems(__, category, items, name, 0);
  };

  calcHeight = () => {
    const { category } = this.props;
    let height = 0;
    if (this.ionList && this.orderListItems && isDefined(category)) {
      let orderListHeight =
        window.innerHeight - document.querySelector('.order-categories').clientHeight - 20;
      let lastCategory = this.ionList.childNodes[this.ionList.childNodes.length - 1];
      if (lastCategory) {
        let lastCategoryHeader = lastCategory.childNodes[0];
        let lastCategoryWrap = lastCategory.childNodes[lastCategory.childNodes.length - 1];
        let lastCategoryWrapItem =
          lastCategoryWrap.childNodes[lastCategoryWrap.childNodes.length - 1];
        let lastCategoryMargin = parseInt(window.getComputedStyle(lastCategory).marginBottom);
        if (orderListHeight && lastCategoryHeader && lastCategoryWrapItem) {
          height =
            orderListHeight -
            (lastCategoryHeader.clientHeight + lastCategoryWrapItem.clientHeight) -
            lastCategoryMargin;
        }
      }
    }

    return height;
  };

  render() {
    const { __, category } = this.props;
    return (
      <div
        className="order-list-items"
        onScroll={(e) => this.props.selectCategoryOnScroll(e)}
        ref={(ref) => (this.orderListItems = ref)}
      >
        <IonList lines="none" ref={(ref) => (this.ionList = ref)}>
          {this.subCategoriesList(__, category, '')}
        </IonList>
        <div style={{ height: this.calcHeight() }}></div>
        <Modal
          className="choose-size-modal"
          isOpen={this.state.isChooseSizeModalOpen}
          onDidDismiss={() => this.setState({ isChooseSizeModalOpen: false })}
        >
          <div>
            <Title>{__('Go Large?')}</Title>
            <Spacer size={1} />
            <IonButton
              onClick={() => this.selectItem({ large: false })}
              expand="block"
              color="secondary"
            >
              <div>
                <NormalText>{__('Regular')}</NormalText>
                <NormalText>{Basket.formatPrice(this.state.selectedItem?.productPrice)}</NormalText>
              </div>
            </IonButton>
            <Spacer size={1} />
            <IonButton
              onClick={() => this.selectItem({ large: true })}
              expand="block"
              color="secondary"
            >
              <div>
                <NormalText>{__('Large')}</NormalText>
                <NormalText>{Basket.formatPrice(this.state.selectedItem?.largePrice)}</NormalText>
              </div>
            </IonButton>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
  };
};

export default connect(mapStateToProps)(withTranslation(orderList));
