import React from 'react';
import { withRouter } from 'react-router';
import { IonButton, isPlatform } from '@ionic/react';
import Basket from '../../lib/basket';
import Stripe from '../../lib/stripe';
import { Capacitor } from '@capacitor/core';
import './index.css';
import { getConfig } from '../../appConfig';

class DrawPayButton extends React.Component {
  state = {
    gPayBtn: null,
  };

  componentDidMount() {
    this.setGPayBtn(this.props.googlePayClient);
  }

  componentDidUpdate(prevProps) {
    if (this.props.googlePayClient !== prevProps.googlePayClient) {
      this.setGPayBtn(this.props.googlePayClient);
    }
  }

  setGPayBtn = (googlePayClient) => {
    if (googlePayClient) {
      const googlePayButton = googlePayClient.createButton({
        buttonColor: 'black',
        buttonSizeMode: 'fill',
        buttonType: 'long',
        onClick: () => Basket.createOrder('google'),
      });
      this.setState({ gPayBtn: googlePayButton });
    }
  };

  render() {
    const { gPayBtn } = this.state;
    const googlePay = getConfig()?.services?.payments?.googlePay;
    const applePay = getConfig()?.services?.payments?.applePay;
    return Stripe.getStripeInstance() && Capacitor.getPlatform() !== 'web'
      ? !isPlatform('ios')
        ? // <IonButton /*className='google-button'*/ expand="block" onClick={ () => Basket.createOrder('google') }> { __('Google Pay') }</IonButton>
          googlePay && (
            <div
              className="google-button"
              onClick={() => Basket.createOrder('google')}
              dangerouslySetInnerHTML={{ __html: gPayBtn && gPayBtn.innerHTML }}
            ></div>
          )
        : applePay && (
            <IonButton
              className="apple-button"
              onClick={() => Basket.createOrder('apple')}
              expand="block"
              color="black"
            >
              <div className="apple-pay-button apple-pay-button-black">
                <span classNeme="logo"></span>
              </div>
            </IonButton>
          )
      : googlePay && (
          <div
            className="google-pay-btn"
            onClick={() => Basket.createOrder('google')}
            dangerouslySetInnerHTML={{ __html: gPayBtn && gPayBtn.innerHTML }}
          ></div>
        );
  }
}

export default withRouter(DrawPayButton);
