import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import { validateProfileData } from '../../lib/utils';
import { NormalText, StrongText } from '../common';
import { setModal } from '../../store/actions';
import { getConfig } from '../../appConfig';
import './index.css';

const ValidateButton = ({ __, dispatch, profile, auth }) => {
  const isAuth = auth.loggedIn;
  const valid = validateProfileData(profile).isValid;
  if (!getConfig().appType.hasEmailValidationEnabled) {
    return null;
  }
  return (
    <>
      {isAuth && !valid ? (
        <>
          <div className="validate-button">
            <NormalText className="validate-status">{__('Not Verified')}</NormalText>
            <StrongText
              onClick={() => dispatch(setModal('isVerfiedModalOpen', true))}
              className="pointer  underlined"
            >
              {__('Verify Account')}
            </StrongText>
          </div>
        </>
      ) : isAuth && valid ? (
        <div className="validate-button valid">
          <NormalText className="validate-status">{__('Verified')}</NormalText>
        </div>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { isVerfiedModalOpen, profile, auth } = store.profile;
  return {
    isVerfiedModalOpen,
    profile,
    auth,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(ValidateButton)));
