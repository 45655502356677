import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import SwipableTabs from '../../components/swipeableTabs';
import './index.css';
import { ApplyPoints } from '../applyPoints';
import ApplyVouchers from '../applyVouchers';
import { Spacer } from '../../components/common';
import RedeemGiftVoucher from '../../components/redeemGiftVoucher';

class ApplyLoyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { __ } = this.props;

    return (
      <Layout
        hideSecondToolbar={true}
        headerTitle={__('Redeem Loyalty')}
        color="transparent"
        scrollY={false}
        headerWithTitle={true}
      >
        <div className="absolute-content scrollable-y">
          <ApplyPoints></ApplyPoints>
          <Spacer />
          <ApplyVouchers></ApplyVouchers>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  return {
    transactionHistory: state.orders.history || [],
    orderHistory: state.orders.orderHistory || [],
  };
};

export default withRouter(withTranslation(connect(stateToProps)(ApplyLoyalty)));
