import React from 'react';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBadge,
  IonMenuToggle,
} from '@ionic/react';
import { scan, basket, arrowBack } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import { SmallText, Subtitle, Title } from '../common';
import { forwardTo, getRouteClassName, isWebConfig, goBack } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import menuWhite from '../../assets/images/menu-white.svg';
import menuDark from '../../assets/images/menu-dark.svg';

import './index.css';

const StaticHeader = ({
  __,
  auth,
  profile,
  history,
  backHandler,
  title,
  headerWithTitle,
  showHamburgerIcon,
  navConfig,
  hasLoyaltyPoints,
}) => {
  const currentPath = history.location.pathname;
  const config = getConfig();
  const authPages = config.general.authRoutes.indexOf(currentPath) !== -1;
  const pagesWithBackButton = config.general.routesWithBackButton.indexOf(currentPath) !== -1;
  const hideStaticHeader = config.theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const routeClassName = getRouteClassName(currentPath, navConfig);
  const routesWithDarkBg = config.general.routesWithDarkBg.indexOf(currentPath) !== -1;

  return (
    <>
      {hideStaticHeader ? null : config.theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader className={'static ' + routeClassName}>
          <IonToolbar className={`${headerWithTitle ? 'no-logo' : ''} primary-toolbar`}>
            <IonButtons slot="start">
              {showHamburgerIcon ? (
                <>
                  {!authPages && !isWebConfig() ? (
                    <IonMenuToggle>
                      <img
                        className="menu-button"
                        src={routesWithDarkBg ? `${menuWhite}` : `${menuDark}`}
                        alt=""
                      ></img>
                    </IonMenuToggle>
                  ) : (
                    <>
                      <IonButton
                        className="header-back-button"
                        onClick={() => (backHandler ? backHandler() : goBack())}
                      >
                        <IonIcon
                          onClick={() => (backHandler ? backHandler() : goBack())}
                          color={routesWithDarkBg ? 'white' : 'dark'}
                          icon={arrowBack}
                          className="icon"
                        />
                      </IonButton>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!authPages && !pagesWithBackButton ? (
                    !isWebConfig() ? (
                      <IonMenuToggle>
                        <img
                          className="menu-button"
                          src={routesWithDarkBg ? `${menuWhite}` : `${menuDark}`}
                          alt=""
                        ></img>
                      </IonMenuToggle>
                    ) : null
                  ) : (
                    <IonButton
                      className="header-back-button"
                      onClick={() => (backHandler ? backHandler() : goBack())}
                    >
                      <IonIcon
                        onClick={() => (backHandler ? backHandler() : goBack())}
                        color={routesWithDarkBg ? 'white' : 'dark'}
                        icon={arrowBack}
                        className="icon"
                      />
                    </IonButton>
                  )}
                </>
              )}
            </IonButtons>
            {!headerWithTitle ? (
              <IonButtons
                style={{
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  position: 'absolute',
                  width: '100%',
                  zIndex: '-1',
                }}
                color="primary"
              >
                <IonButton className="image-button" onClick={() => forwardTo('/dashboard')} />
              </IonButtons>
            ) : (
              <IonButtons
                style={{
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  position: 'absolute',
                  width: '100%',
                  zIndex: '-1',
                }}
              >
                <IonButton className="header-title" onClick={() => forwardTo('/dashboard')}>
                  <Title>{title}</Title>
                </IonButton>
              </IonButtons>
            )}
            <IonButtons style={{ marginRight: '10px' }} slot="end">
              {!authPages && !isWebConfig() ? (
                config.appType.hasLoyalty ? (
                  hasLoyaltyPoints && auth.loggedIn && profile.available_balance > 0 ? (
                    <div onClick={() => forwardTo('/loyalty')} className="header-points">
                      <>
                        <Subtitle
                          className="bold header-subtitle"
                          color={routesWithDarkBg ? 'white' : 'dark'}
                        >
                          {profile.available_balance}
                        </Subtitle>
                        <SmallText color={routesWithDarkBg ? 'white' : 'dark'}>
                          {__('pts')}
                        </SmallText>
                      </>
                    </div>
                  ) : (
                    <IonButton
                      color="dark"
                      button
                      clear
                      onClick={() => forwardTo('/loyalty', { tab: 'scan' })}
                    >
                      <IonIcon
                        color={routesWithDarkBg ? 'white' : 'dark'}
                        slot="icon-only"
                        icon={scan}
                      />
                    </IonButton>
                  )
                ) : null
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  const { navConfig } = store.common;
  const { profile, auth } = store.profile;
  return {
    basketUpdated: orders.basketUpdated,
    navConfig,
    profile,
    auth,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
