import React, { Component } from 'react';
import {
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonItem,
  IonTextarea,
  IonIcon,
  IonList,
} from '@ionic/react';
import Checkout from '../checkout';
import { checkmarkCircle, person } from 'ionicons/icons';
import successIcon from '../../assets/images/success-icon.png';
import { withTranslation } from '../../lib/translate';
import moment from '../../lib/moment';

import {
  BigLabel,
  FieldError,
  NormalText,
  SmallText,
  Spacer,
  StrongText,
  Subtitle,
  Title,
} from '../../components/common';

import Modal from '../../components/modal';
import { forwardTo, validateForm } from '../../lib/utils';
import Basket from '../../lib/basket';
import { connect } from 'react-redux';
import api from '../../lib/api';
import { getConfig } from '../../appConfig';
import Mobiscroll from '../../components/mobiscroll';

const { DatePicker, SelectOption } = Mobiscroll;

class SendGiftVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giftVoucherAmount: 0,
      email: '',
      recipientAdded: false,
      checkoutPage: false,
      voucherSummaryPage: false,
      voucherMessageModalOpen: false,
      giftVoucherMesageFromModal: '',
      giftVoucherMessage: '',
      recipientEmailAddressModalOpen: false,
      selectedGiftVoucher: false,
      sendNewVoucher: true,
      showSuccessSend: false,
      showSuccessResend: false,
      showSuccessUpdate: false,
      giftVoucherValues: [],
      giftVoucherDate: null,
      voucherIsSent: false,
    };
    this.voucherValidator = {
      email: { type: 'email', required: true },
    };
  }

  resetState(newVoucher) {
    this.setState({
      giftVoucherAmount: this.state.giftVoucherValues[1],
      email: '',
      recipientAdded: false,
      checkoutPage: false,
      voucherSummaryPage: false,
      voucherMessageModalOpen: false,
      giftVoucherMesageFromModal: '',
      giftVoucherMessage: '',
      recipientEmailAddressModalOpen: false,
      selectedGiftVoucher: false,
      sendNewVoucher: newVoucher,
      showSuccessSend: false,
      showSuccessResend: false,
      showSuccessUpdate: false,
    });
  }

  sendGiftVoucher = () => {
    const { dispatch, restaurants, profile } = this.props;

    const { email } = this.state;
    let voucherErrors = validateForm(this.voucherValidator, { email });
    this.setState({ voucherErrors });
    if (Object.keys(voucherErrors).length === 0) {
      const data = {
        points_value: this.state.giftVoucherAmount * 100,
        sender_name: this.props.profile.first_name,
        recipient_name: '',
        recipient_email: this.state.email,
        personal_message: this.state.giftVoucherMessage,
        send_on_date: this.state.giftVoucherDate,
      };
      Basket.reset();
      Basket.setCollectionTime(null);
      Basket.addToBasket({
        item: { productPrice: this.state.giftVoucherAmount, productName: 'eGift Voucher' },
        quantity: 1,
      });
      Basket.setDeliveryOption({
        id: 'gift-vouchers',
      });
      Basket.setGift();
      Basket.setRestaurant(restaurants.find((restaurant) => restaurant.name === 'Vouchers'));
      Basket.setServicePercentage(0);
      dispatch({
        type: 'SET_GIFT_VOUCHER_DATA',
        value: data,
      });

      if (this.props.auth.loggedIn) {
        this.setState({
          checkoutPage: true,
          voucherSummaryPage: false,
        });
        this.props.checkoutPageHandler();
      } else {
        forwardTo('/login', { fromGiftVoucher: true });
      }
    }
  };
  resendGiftVoucher = () => {
    const { dispatch } = this.props;
    const { email, selectedGiftVoucher, giftVoucherMessage, giftVoucherDate } = this.state;
    let voucherErrors = validateForm(this.voucherValidator, { email });
    if (Object.keys(voucherErrors).length === 0) {
      const data = {
        gift_id: selectedGiftVoucher.id,
        recipient_email: email,
        personal_message: giftVoucherMessage,
        send_on_date: giftVoucherDate,
      };
      dispatch({
        type: 'RESEND_GIFT_VOUCHER',
        data,
      });
    }
  };
  updateGiftVoucher = () => {
    const { dispatch } = this.props;
    const { email, selectedGiftVoucher, giftVoucherMessage, giftVoucherDate } = this.state;
    let voucherErrors = validateForm(this.voucherValidator, { email });
    if (Object.keys(voucherErrors).length === 0) {
      const data = {
        gift_id: selectedGiftVoucher.id,
        recipient_email: email,
        personal_message: giftVoucherMessage,
        send_on_date: giftVoucherDate,
      };
      dispatch({
        type: 'UPDATE_GIFT_VOUCHER',
        data,
      });
    }
  };
  proceedeToOrderSummary = () => {
    const { email } = this.state;
    let voucherErrors = validateForm(this.voucherValidator, { email });
    this.setState({ voucherErrors });
    if (Object.keys(voucherErrors).length === 0) {
      this.setState({ recipientAdded: true, voucherSummaryPage: true });
      this.props.summaryPageHandler();
    }
  };
  formatVoucherValues = () => {
    const values = this.state.giftVoucherValues;
    const data = values.map((value) => {
      return { text: Basket.formatPrice(value), value };
    });

    return data;
  };
  getDateArray = (obj) => {
    var start = obj.startDate.clone();
    var end = obj.endDate.clone();
    var res = [];
    while (start.isBefore(end)) {
      res.push(start.toDate());
      start.add(1, 'd');
    }
    return res;
  };
  formatDateValues = () => {
    const startDate = moment();
    const endDate = moment().add(30, 'days');
    const daysInBetween = this.getDateArray({ startDate, endDate });
    const data = daysInBetween.map((date) => {
      return {
        text: moment(date).format('DD MMMM YYYY'),
        value: moment(date).format('MM-DD-YYYY'),
      };
    });
    return data;
  };
  drawSendVoucherContent = (__) => {
    const giftVoucherSendDaysLimit = getConfig().general?.giftVoucherSendDaysLimit;
    const numberOfDays = giftVoucherSendDaysLimit ? giftVoucherSendDaysLimit : 30;
    const maxDate = new Date(new Date().setDate(new Date().getDate() + numberOfDays));
    const dateValues = this.formatDateValues();

    return (
      <div className="send-voucher-wrapper">
        {/* <img className="centered" src={this.props.clientProfile.logo_image} /> */}
        <Title className="default-padding">{__('Send eGift voucher')}</Title>
        {/* <NormalText>{__(this.props.clientProfile.gift_voucher_description)}</NormalText> */}
        <Spacer />
        <div className="input-field-container">
          <NormalText>{__('How much do you want to send?')}</NormalText>
          <IonItem lines="none" className="input-field-wrapper dropdown-field">
            <SelectOption
              cssClass="language-picker"
              display="center"
              onSet={(e, a) =>
                this.setState({
                  giftVoucherAmount: a.getVal(),
                })
              }
              data={this.formatVoucherValues()}
              label="Location"
              value={this.state.giftVoucherValues[1]}
              cancelText={__('Cancel')}
              setText={__('OK')}
            />
          </IonItem>
        </div>
        <Spacer size={1} />
        <div className="input-field-container">
          <NormalText>{__('Where shall we send the eGift voucher?')}</NormalText>
          <IonItem lines="none" className="input-field-wrapper">
            <IonInput
              onIonInput={(e) => this.setState({ email: e.target.value })}
              type="email"
              pattern="email"
              inputmode="email"
              value={this.state.email}
              placeholder="Enter recipient email address"
            ></IonInput>
          </IonItem>
          {this.state.voucherErrors && this.state.voucherErrors.email && (
            <FieldError className="field-error" value={__(this.state.voucherErrors.email)} />
          )}
        </div>
        <Spacer size={1} />
        <div className="input-field-container">
          <NormalText>{__('When shall we send the voucher?')}</NormalText>
          <IonItem lines="none" className="input-field-wrapper  dropdown-field">
            <SelectOption
              display="center"
              onSet={(e, a) =>
                this.setState({
                  giftVoucherDate: a.getVal(),
                })
              }
              data={dateValues}
              label="Location"
              value={this.state.giftVoucherDate}
              cancelText={__('Cancel')}
              setText={__('OK')}
              onInit={() => {
                if (!this.state.giftVoucherDate) {
                  this.setState({ giftVoucherDate: dateValues[0].value });
                }
              }}
            />
          </IonItem>
        </div>

        <Spacer size={1} />
        <IonButton
          disabled={this.state.email.length == 0}
          onClick={() => {
            this.proceedeToOrderSummary();
          }}
          color="secondary"
          expand="block"
        >
          {__('Send eGift Voucher')}
        </IonButton>
        {this.props.sentGiftVouchers.length > 0 && (
          <IonButton
            onClick={() => {
              this.resetState(false);
            }}
            fill="clear"
            expand="block"
            className="link underlined uppercase"
            color="secondary"
          >
            {__('View All Sent Vouchers')}
          </IonButton>
        )}
        <IonButton
          onClick={() => {
            forwardTo('/loyalty');
          }}
          fill="clear"
          expand="block"
          className="link underlined uppercase"
          color="secondary"
        >
          {__('Redeem an eGift voucher')}
        </IonButton>
      </div>
    );
  };

  drawVoucherOrderSummary = (__, editable, resend) => {
    const {
      email,
      giftVoucherAmount,
      giftVoucherMessage,
      giftVoucherDate,
      voucherIsSent,
    } = this.state;
    const giftVoucherSendDaysLimit = getConfig().general?.giftVoucherSendDaysLimit;
    const numberOfDays = giftVoucherSendDaysLimit ? giftVoucherSendDaysLimit : 30;
    const maxDate = new Date(new Date().setDate(new Date().getDate() + numberOfDays));
    return (
      <>
        <div className="order-summary-wrapper">
          <Title>{__('Order Summary')}</Title>
          <Spacer size={1} />
          <div className="box-wrapper gift-voucher-order-content">
            <IonIcon icon={checkmarkCircle}></IonIcon>
            <div className="gift-voucher-order-content-summary">
              <Subtitle className="bold">
                {Basket.formatPrice(giftVoucherAmount)} {__('eGift Voucher')}
              </Subtitle>
              <NormalText>
                {__('Recipient')}
                {': '}
                {email}
              </NormalText>
              {giftVoucherDate && (
                <NormalText className="block">
                  {__('Send date')}
                  {': '}
                  {giftVoucherDate}
                </NormalText>
              )}
            </div>
            {editable && (
              <div
                className="underlined pointer"
                onClick={() => {
                  this.setState({ recipientEmailAddressModalOpen: true });
                }}
              >
                {__('Edit')}
              </div>
            )}
          </div>
          <Spacer size={1} />

          {giftVoucherMessage == '' ? (
            <>
              {editable && (
                <div>
                  <IonButton
                    onClick={() => {
                      this.setState({ giftVoucherMessageModalOpen: true });
                    }}
                    expand="block"
                    fill="clear"
                    color="secondary"
                    className="link underlined"
                  >
                    {__('Add a personal message')}
                  </IonButton>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="">
                <NormalText>{__('Personal Message')}</NormalText>
                <IonRow className="box-wrapper">
                  <IonCol size="9">
                    <NormalText>{giftVoucherMessage}</NormalText>
                  </IonCol>
                  <IonCol className="righted paddLR underlined pointer">
                    {editable && (
                      <div
                        onClick={() => {
                          this.setState({ giftVoucherMessageModalOpen: true });
                        }}
                      >
                        {__('Edit')}
                      </div>
                    )}
                  </IonCol>
                </IonRow>
              </div>
              <Spacer size={1} />
            </>
          )}
          <div className="box-wrapper gift-voucher-summary-table">
            <div>
              <NormalText>{__('1x eGift Voucher')}</NormalText>
              <NormalText>{Basket.formatPrice(giftVoucherAmount)}</NormalText>
            </div>
            <div>
              <StrongText>{__('TOTAL TO PAY')}</StrongText>
              <StrongText>{Basket.formatPrice(giftVoucherAmount)}</StrongText>
            </div>
          </div>
          <Spacer size={1} />
          <div>
            <StrongText>
              {__('You will receive')} {giftVoucherAmount}{' '}
              {__('loyalty points added to your loyalty points balance!')}
            </StrongText>
          </div>
          <Spacer size={2} />

          {!resend ? (
            <IonButton onClick={this.sendGiftVoucher} expand="block" color="secondary">
              {__('Checkout')}
            </IonButton>
          ) : (
            <>
              {editable && (
                <>
                  {voucherIsSent ? (
                    <IonButton onClick={this.resendGiftVoucher} expand="block" color="secondary">
                      {__('Resend')}
                    </IonButton>
                  ) : (
                    <IonButton onClick={this.updateGiftVoucher} expand="block" color="secondary">
                      {__('Edit')}
                    </IonButton>
                  )}
                </>
              )}
            </>
          )}

          <IonButton
            onClick={() => {
              this.props.detailsPageHandler();
              this.resetState(false);
            }}
            expand="block"
            className="link underline"
          >
            {__('Cancel')}
          </IonButton>
          <Modal
            onDidDismiss={() =>
              this.setState({
                giftVoucherMessageModalOpen: false,
                giftVoucherMesageFromModal: this.state.giftVoucherMessage,
              })
            }
            className="personal-message-modal"
            isOpen={this.state.giftVoucherMessageModalOpen}
          >
            <Title>{__('Personal message')}</Title>
            <Spacer size={1} />
            <IonItem lines="none" className="input-field-wrapper">
              <IonTextarea
                rows={5}
                placeholder={__('Enter your message')}
                value={this.state.giftVoucherMesageFromModal}
                onIonChange={(event) =>
                  this.setState({ giftVoucherMesageFromModal: event.detail.value })
                }
              ></IonTextarea>
            </IonItem>
            <Spacer size={1} />
            <IonButton
              color="secondary"
              expand="block"
              onClick={() => {
                this.setState({
                  giftVoucherMessage: this.state.giftVoucherMesageFromModal,
                  giftVoucherMessageModalOpen: false,
                });
              }}
            >
              {' '}
              {__('Continue')}
            </IonButton>
          </Modal>
          <Modal
            onDidDismiss={() => this.setState({ recipientEmailAddressModalOpen: false })}
            className="personal-message-modal"
            isOpen={this.state.recipientEmailAddressModalOpen}
          >
            <Title>{__('Edit email')}</Title>
            <Spacer size={1} />
            <IonItem lines="none" className="input-field-wrapper">
              <IonInput
                value={email}
                onIonInput={(event) => this.setState({ email: event.detail.value })}
              ></IonInput>
            </IonItem>
            <Spacer size={1} />
            <IonButton
              color="secondary"
              expand="block"
              onClick={() => {
                this.setState({
                  email: this.state.email,
                  recipientEmailAddressModalOpen: false,
                });
              }}
            >
              {' '}
              {__('Continue')}
            </IonButton>
          </Modal>
        </div>
      </>
    );
  };

  openVoucherContent = (item) => {
    const data = {
      recipient_email: item.recipient_email,
      sender_name: this.props.profile.first_name,
      recipient_name: item.recipient_name,
      points_value: item.points_value,
      personal_message: item.personal_message,
      send_on_date: item.send_on_date,
      is_sent: item.is_sent,
    };
    this.setState({
      giftVoucherAmount: data.points_value / 100,
      email: data.recipient_email,
      giftVoucherMessage: data.personal_message,
      giftVoucherMesageFromModal: data.personal_message,
      voucherSummaryPage: true,
      selectedGiftVoucher: item,
      giftVoucherDate: moment(data.send_on_date).format('MM-DD-YYYY'),
      voucherIsSent: data.is_sent,
    });
    this.props.summaryPageHandler();
  };
  async componentDidMount() {
    const giftVoucherData = this.props.giftVoucherData;
    const config = await api.getFrontEndAppConfig();
    this.setState({
      giftVoucherValues: getConfig().general.giftVouchers,
      giftVoucherAmount: getConfig().general.giftVouchers[1],
    });
    if (giftVoucherData) {
      this.setState({
        giftVoucherAmount: giftVoucherData.points_value / 100,
        email: giftVoucherData.recipient_email,
        giftVoucherMessage: giftVoucherData.personal_message,
        giftVoucherMesageFromModal: giftVoucherData.personal_message,
        giftVoucherData: giftVoucherData.send_on_date,
        voucherSummaryPage: false,
        sendNewVoucher: true,
        checkoutPage: true,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.giftVoucherSent) {
      this.setState({
        showSuccessSend: true,
      });
      forwardTo('/gift-vouchers');
    }
    if (this.props.giftVoucherRedeemed) {
      this.setState({
        showSuccessResend: true,
      });
      forwardTo('/gift-vouchers');
    }
    if (this.props.giftVoucherUpdated) {
      this.setState({
        showSuccessUpdate: true,
      });
      forwardTo('/gift-vouchers');
    }
  }

  render() {
    const { __, sentGiftVouchers, clientProfile } = this.props;
    const {
      selectedGiftVoucher,
      sendNewVoucher,
      showSuccessSend,
      showSuccessResend,
      showSuccessUpdate,
    } = this.state;

    return (
      <>
        {showSuccessSend || showSuccessResend || showSuccessUpdate ? (
          <div className="success-gift-voucher centered">
            <Title className="default-padding centered">{__('Success')}</Title>
            <Spacer size={1} />
            <img src={successIcon}></img>
            <Spacer size={1} />
            {showSuccessSend && (
              <NormalText>
                {__('Your payment was successful and your eGift voucher has been sent')}
              </NormalText>
            )}
            {showSuccessResend && (
              <NormalText>{__('Your eGift voucher has been resent')}</NormalText>
            )}
            {showSuccessUpdate && (
              <NormalText>{__('Your eGift voucher has been updated')}</NormalText>
            )}
            <Spacer size={1} />
            <IonButton
              expand="block"
              color="secondary"
              onClick={() => {
                this.props.detailsPageHandler();
                this.resetState(false);
                Basket.reset();
              }}
            >
              {__('Continue')}
            </IonButton>
          </div>
        ) : (
          <>
            {this.props.detailsPage && !sendNewVoucher ? (
              <>
                <div className="sent-gift-vouchers-wrapper">
                  <Title className="default-padding">{__('Recently sent')}</Title>
                  <Spacer size={1} />

                  <IonList className="gift-voucher-history-list">
                    {sentGiftVouchers.map((voucher) => (
                      <IonItem
                        className="box-wrapper"
                        lines="none"
                        key={voucher.id}
                        style={{ marginBottom: '10px' }}
                        onClick={() => {
                          this.openVoucherContent(voucher);
                        }}
                      >
                        <IonIcon icon={person} />
                        <div className="gift-voucher-user">
                          <StrongText className="ellipsis">{voucher.recipient_email}</StrongText>
                          {voucher.redeemed_on ? (
                            <SmallText className="gift-voucher-date" color="success">
                              {__('Redeemed on')}:{' '}
                              {moment(voucher.redeemed_on).format('Do MMM yyyy')}
                            </SmallText>
                          ) : (
                            <>
                              {' '}
                              {voucher.is_sent ? (
                                <SmallText className="gift-voucher-date">
                                  {__('Email sent')}:{' '}
                                  {moment(voucher.send_on_date).format('Do MMM yyyy')}
                                </SmallText>
                              ) : (
                                <SmallText className="gift-voucher-date">
                                  {__('Scheduled Date')}:{' '}
                                  {moment(voucher.send_on_date).format('Do MMM yyyy')}
                                </SmallText>
                              )}
                            </>
                          )}
                        </div>
                        {!voucher.is_paid ? (
                          <IonButton
                            className="gift-voucher-resend-button square-button"
                            style={{ height: '24px' }}
                            color="gray"
                          >
                            <SmallText>{__('Processing Payment')}</SmallText>
                          </IonButton>
                        ) : (
                          <>
                            {voucher.redeemed_on ? (
                              <IonButton
                                style={{ height: '24px' }}
                                color="secondary"
                                disabled={true}
                              >
                                {__('Redeemed')}
                              </IonButton>
                            ) : (
                              <>
                                {voucher.is_sent ? (
                                  <IonButton
                                    className="gift-voucher-resend-button square-button"
                                    style={{ height: '24px' }}
                                    color="secondary"
                                  >
                                    <svg
                                      style={{ marginRight: '5px' }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="#fff"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5" />
                                      <path d="M3 6l9 6l9 -6" />
                                      <path d="M15 18h6" />
                                      <path d="M18 15l3 3l-3 3" />
                                    </svg>
                                    <SmallText>{__('Resend')}</SmallText>
                                  </IonButton>
                                ) : (
                                  <>
                                    <IonButton
                                      className="gift-voucher-resend-button square-button"
                                      style={{ height: '24px' }}
                                      color="secondary"
                                    >
                                      <SmallText>{__('Edit')}</SmallText>
                                    </IonButton>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </IonItem>
                    ))}
                  </IonList>
                  <Spacer size={1} />
                </div>
                <div className="flex-min">
                  <IonButton expand="block" color="secondary" onClick={() => this.resetState(true)}>
                    {__('Send a new eGift voucher')}
                  </IonButton>
                </div>
              </>
            ) : (
              <>
                {selectedGiftVoucher ? (
                  <>
                    {this.drawVoucherOrderSummary(
                      __,
                      selectedGiftVoucher.redeemed_on ? false : true,
                      true,
                    )}
                  </>
                ) : (
                  <>
                    {!this.props.checkoutPage &&
                      !this.props.summaryPage &&
                      this.drawSendVoucherContent(__)}
                    {this.props.summaryPage && this.drawVoucherOrderSummary(__, true, false)}
                    {this.props.checkoutPage && <Checkout giftVoucher={true}></Checkout>}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}
const stateToProps = (state) => {
  const { restaurants } = state.restaurants;
  const { giftVoucherData } = state.orders;
  const { profile } = state.profile;
  return {
    restaurants: restaurants || [],
    profile,
    giftVoucherData,
    auth: state.profile.auth,
    clientProfile: state.common.clientProfile,
  };
};
export default connect(stateToProps)(withTranslation(SendGiftVoucher));
