import React from 'react';
import { IonIcon, IonInput, IonButton } from '@ionic/react';
import './index.css';
import { isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import { NormalText, SmallText, StrongText } from '../common';

import check from '../../assets/images/Deli_Check.svg';
import wrong from '../../assets/images/Deli_Wrong.svg';

const { DatePicker } = Mobiscroll;

class ValidateInput extends React.Component {
  state = {
    value: this.props.value || '',
    show: false,
  };

  componentDidMount() {
    if (this.props.name) {
      const valid = this.props.validatedData[this.props.name];
      this.setState({ show: valid });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
    if (prevProps.validatedData[this.props.name] !== this.props.validatedData[this.props.name]) {
      if (this.props.name) {
        const valid = this.props.validatedData[this.props.name];
        this.setState({ show: valid });
      }
    }
  }

  togglePass = () => {
    const { type } = this.props;
    if (type && type === 'email') {
      const value =
        isDefined(this.state.value) &&
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.value);
      this.setState({ show: value });
    } else {
      const value = isDefined(this.state.value) && this.state.value !== '';
      this.setState({ show: value });
    }
  };

  onChange = (e) => {
    const { onIonChange } = this.props;
    this.setState({ value: e.target.value }, () => {
      this.togglePass();
    });
    if (onIonChange) {
      onIonChange(e);
    }
  };

  render() {
    const { show, value } = this.state;
    const { __, label, type, ...rest } = this.props;
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    return (
      <div className="okx-validate-input-wrapper">
        <div className="okx-validate-input-holder inline-input inline-input--validate">
          {type === 'date' ? (
            <div className="date-picker-wrapper inline-input inline-input--validate">
              <DatePicker
                className="data-picker-input"
                display="bottom"
                setText="Done"
                max={yesterday}
                onSet={(e, a) => this.onChange({ target: { value: a.element.value } })}
                {...rest}
                value={value}
              />
            </div>
          ) : null}
          {type === 'text' ? (
            <IonInput {...rest} value={value} onIonInput={this.onChange}></IonInput>
          ) : null}
          {type === 'email' ? (
            <IonInput readonly {...rest} value={value} onIonInput={this.onChange}></IonInput>
          ) : null}
          {type === 'tel' ? (
            <IonInput {...rest} value={value} onIonInput={this.onChange}></IonInput>
          ) : null}

          {type === 'email_verified' ? (
            <>
              {show ? (
                <NormalText>{__('Email Address Verified')}</NormalText>
              ) : (
                <StrongText
                  className="bold underlined secondary-color pointer"
                  onClick={() => this.props.onButtonClicked()}
                >
                  {__('Resend address validation email')}
                </StrongText>
              )}
            </>
          ) : null}
        </div>
        <IonButton
          fill="clear"
          size="small"
          onTouchStart={() => this.togglePass()}
          onTouchEnd={() => this.togglePass()}
        >
          <IonIcon slot="icon-only" icon={show ? check : wrong} />
        </IonButton>
      </div>
    );
  }
}

export default withTranslation(ValidateInput);
