import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { withRouter } from 'react-router';
// import { arrowForward } from 'ionicons/icons'
import { NormalText, SmallText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import NoDataSecond from '../../components/noDataSecond';
import Basket from '../../lib/basket';
import earnedArrow from '../../assets/images/earned-arrow.svg';
import redeemedArrow from '../../assets/images/redeemed-arrow.svg';
import { getConfig } from '../../appConfig';
import { forwardTo, isDefined } from '../../lib/utils';
import { showToast } from '../../store/actions';
import { chevronDown, chevronUp } from 'ionicons/icons';

const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const getDeliveryLabel = (deliveryLabel) => {
  switch (deliveryLabel) {
    case 'Scheduled Delivery':
      return 'Delivery Order';
    case 'Order for Delivery':
      return 'Delivery Order';
    case 'Click & Collect':
      return 'Collection Order';
    default:
      return deliveryLabel;
  }
};

const HistoryTab = withRouter(
  ({ __, transactionHistory, getTransactionHistory, history, restaurants, dispatch, ...rest }) => {
    const type = rest.type || 'order';
    const handleRowClick = (item) => {
      if (type === 'order' && item.id >= 61219) {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };
    const orderAgain = (past_orders, option) => {
      const selectedRestaurant = restaurants.filter(
        (restaurant) => restaurant.id == past_orders.restaurant_id,
      );
      if (selectedRestaurant.length > 0) {
        if (option.id == 'collection') {
          const orderType = getConfig().delivery.filter((el) => el.id == 'collection');
          const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
          if (
            selectedRestaurant[0].can_collection_order &&
            selectedRestaurant[0].is_published &&
            !orderTypeDisabled
          ) {
            Basket.reset();
            Basket.setDeliveryOption(option);
            forwardTo(option.route, { selectedRestaurant: past_orders });
          } else {
            dispatch(showToast('This restaurant is currently not accepting orders.', 'warning'));
          }
        }
        if (option.id == 'table') {
          const orderType = getConfig().delivery.filter((el) => el.id == 'table');
          const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
          if (
            selectedRestaurant[0].can_table_order &&
            selectedRestaurant[0].is_published &&
            !orderTypeDisabled
          ) {
            Basket.reset();
            Basket.setDeliveryOption(option);
            forwardTo(option.route, { selectedRestaurant: past_orders });
          } else {
            dispatch(showToast('This restaurant is currently not accepting orders.', 'warning'));
          }
        }
        if (option.id == 'charter-delivery') {
          const orderType = getConfig().delivery.filter((el) => el.id == 'charter-delivery');
          const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
          if (
            selectedRestaurant[0].can_charter_delivery_order &&
            selectedRestaurant[0].is_published &&
            !orderTypeDisabled
          ) {
            Basket.reset();
            Basket.setOrderType('charter-delivery');

            Basket.setDeliveryOption(option);
            forwardTo(option.route, { selectedRestaurant: past_orders });
          } else {
            dispatch(showToast('This restaurant is currently not accepting orders.', 'warning'));
          }
        }
      } else {
        dispatch(showToast('This restaurant is no longer accepting orders.', 'warning'));
      }
    };
    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];

    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="history-content">
          {(transactionHistory || []).length === 0 ? (
            <NoDataSecond label="Looks like you haven't earned any loyalty yet. Once you do your updates will appear here." />
          ) : (
            <IonGrid className="box-wrapper ">
              {transactionHistory.map((i, index) => {
                const {
                  stamp_power,
                  transaction_date,
                  location_name,
                  label,
                  item,
                  business_location_id,
                  new_rate,
                  id
                } = i;
                // const splitedLabel = label.split('#').splice(1).join('')
                const labelLocationName = label.split('#').splice(0).join('');
                let orderLabel = Basket.getOrderType(i.item);
                let option = (delivery || []).find((d) => d.id.includes(orderLabel));
                let status = item && item.status ? item.status : '';
                let orderId = i && i.item ? ' #' + i.item.id : '';
                return (status === 'CREATED' ||
                  status === 'created' ||
                  status === 'NEW' ||
                  status === 'new') &&
                  !item?.is_gift ? null : (
                  <>
                    <IonRow key={index} className="history-item" onClick={(e) => i?.items?.length > 0 ? e.target.classList.toggle('active') : handleRowClick(item)}>
                      <IonCol size={type == 'order' ? '6' : '8'} >
                        {!item?.is_gift && (
                          <SmallText
                            color="primary"
                            className={`ellipsis block ${type === 'order' && 'order-location-name'
                              } history-small-text `}
                          >
                            {type === 'order' ? labelLocationName : location_name}
                          </SmallText>
                        )}
                        {!item?.is_gift ? (
                          <Subtitle className="block ">
                            {type === 'order'
                              ? option
                                ? __(getDeliveryLabel(option.label))
                                : ''
                              : __(label)}
                          </Subtitle>
                        ) : (
                          <StrongText className="block ">{__('eGift Voucher')}</StrongText>
                        )}

                        <NormalText color="primary" className="ellipsis block history-small-text">
                          {item
                            ? transaction_date
                            : Basket.getDate(transaction_date).format('DD MMMM YYYY [at] h:mm a')}
                        </NormalText>
                        {i?.items?.length > 0 &&
                          <NormalText class='underlined'>{__('View details')}</NormalText>

                        }
                      </IonCol>
                      {type === 'order' ? (
                        <>
                          {item.id >= 61219 && (
                            <IonCol
                              style={{ alignSelf: 'center', textAlign: 'end' }}
                              className="order-button-col-width"
                            >
                              <>
                                {status == 'new' || status == 'NEW' ? (
                                  <IonButton class="square-button" color="gray">
                                    {__('Processing Payment')}
                                  </IonButton>
                                ) : (
                                  <>
                                    {!item.is_gift && (
                                      <IonButton
                                        class="square-button"
                                        color="secondary"
                                        onClick={() => orderAgain(item, option)}
                                      >
                                        {__('Repeat Order')}
                                      </IonButton>
                                    )}
                                  </>
                                )}
                              </>
                            </IonCol>
                          )}
                        </>
                      ) : (
                        <IonCol
                          style={{ alignSelf: 'center', display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <div className="transaction-points-wrapper">
                            {business_location_id == '-4' ||
                              business_location_id == '-5' ||
                              business_location_id == '-6' ? (
                              <>
                                {business_location_id == '-4' && <Title>{new_rate}</Title>}
                                {business_location_id == '-5' && (
                                  <div>
                                    <IonIcon color="secondary" icon={chevronDown} />
                                    <Title className="secondary-color">{new_rate}</Title>
                                  </div>
                                )}
                                {business_location_id == '-6' && (
                                  <div>
                                    <IonIcon color="success" icon={chevronUp} />
                                    <Title className="success-color">{new_rate}</Title>
                                  </div>
                                )}
                                <StrongText>{__('tier')}</StrongText>
                              </>
                            ) : (
                              <>
                                <Title className={stamp_power < 0 ? ' ' : ' success-color'}>
                                  {stamp_power < 0 ? '-' : '+'}
                                  {Math.abs(stamp_power)}
                                </Title>
                                <NormalText className="bold">{__('points')}</NormalText>
                              </>
                            )}
                          </div>
                        </IonCol>
                      )}
                    </IonRow>
                    <IonRow class={`history-expand history-expand-${id}`}>
                      <div>
                        <Subtitle >{__('Items')}</Subtitle>
                        {i?.items?.map(el => (
                          <div><Subtitle>{`${el.quantity} x ${el.product_name} ${Basket.formatPrice(el.product_price / 100, true)}`}</Subtitle></div>
                        ))}
                      </div>
                    </IonRow>
                  </>
                );
              })}
            </IonGrid>
          )}
        </div>
      </>
    );
  },
);

export default withTranslation(HistoryTab);
