import React from 'react';
import {
  IonButton,
  IonInput,
  IonToggle,
  IonItem,
  IonLabel,
  IonList,
  IonAlert,
  IonCheckbox,
} from '@ionic/react';
import { connect } from 'react-redux';
// import { getConfig } from '../../appConfig'
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute, goBack } from '../../lib/utils';
import {
  registerRequest,
  setModal,
  setSysLocale,
  setRegisterForm,
  updateProfile,
} from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import { beforeShowTimePicker, beforeCloseTimePicker } from '../../store/actions';
import {
  FieldError,
  Title,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
  Subtitle,
} from '../../components/common';
import { validateForm } from '../../lib/utils';
import Loading from '../../components/spinner';
import './index.css';
import Mobiscroll from '../../components/mobiscroll';
import { getConfig } from '../../appConfig';
import { Device } from '@capacitor/device';

const { DatePicker, SelectOption } = Mobiscroll;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserAgeModalOpen: false,
      passwordAlertOpen:false,
      formErrors: {},
      showAdditionalDetails: false,
      fromGiftVoucher: this.props?.location?.state?.fromGiftVoucher,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.formConfig = {
      email: { type: 'email', required: true },
      password: { type: 'password', required: true },
      repeat_password: { type: 'repeat_password', required: true },
      first_name: { type: 'text', required: true },
      last_name: { type: 'text', required: true },
      mobile: { type: 'tel', required: false },
      birthday: { type: 'text', required: true },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
      existing_loyalty_card_number: { type: 'existing_loyalty_card_number', required: false },
      location: { type: 'location', required: true }
    };
  }

  handleInput(key, val) {
    const { dispatch } = this.props;
    dispatch(setRegisterForm(key, val));
    if (key === 'accepted_terms_and_conditions') {
      let formErrors = { ...this.state.formErrors };
      formErrors.accepted_terms_and_conditions = undefined;
      this.setState({ formErrors });
    }
  }

  validateForm(formConfig = this.formConfig) {
    const { registerFormData } = this.props;
    let formErrors = validateForm(formConfig, registerFormData);


    this.setState({ formErrors }, () => {
      if (formErrors.password && formErrors.password !== 'Required field') {
        this.setState({ passwordAlertOpen: true })
      }
    });
    if (formErrors.birthday == 'user_not_18') {
      this.setState({ isUserAgeModalOpen: true });
    }
    return Object.keys(formErrors).length === 0;
  }

  handleRegister() {
    const { protectedReferrer } = this.props;
    this.props.dispatch(registerRequest());
  }
  componentDidMount() {
    const { loggedIn } = this.props.auth;
    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute(this.props.navConfig);
      forwardTo(defaultRoute.path, {
        fromLogin: this.props?.location?.state?.fromGiftVoucher ? true : false,
      });
    }
  }

  returnToLogin = (history) => history.goBack();

  navToTerms = () => forwardTo('/terms');
  backHandler = () => {
    if (!this.state.showTime) {
      goBack();
    } else {
      this.setState({ showTime: false });
    }
  };
  registerUser = () => {
    if (this.validateForm()) {
      this.handleRegister();
    }
  };
  render() {
    const { __, isRegisterModalOpen, registerFormData } = this.props;
    const email = registerFormData.email;
    const password = registerFormData.password;
    const repeat_password = registerFormData.repeat_password;
    const first_name = registerFormData.first_name;
    const last_name = registerFormData.last_name;
    const mobile = registerFormData.mobile;
    const birthday = registerFormData.birthday;
    const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
    const is_subscribed = registerFormData.is_subscribed;
    const location = registerFormData.location;
    const existing_loyalty_card_number = registerFormData.existing_loyalty_card_number;
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const { loggedIn } = this.props.auth;
    const defaultDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    return (
      <Loading transparent>
        <Layout
          hideSecondToolbar={true}
          hideBack={true}
          headerTitle={__('Register')}
          color="transparent"
          contentClassName="register-background"
          backHandler={this.backHandler}
        >
          <div className="absolute-content scrollable-y">
            <Title className="uppercase">{__('Create Account')}</Title>
            <NormalText className="block">
              {__(
                'Create an account to earn & redeem loyalty points, access exclusive vouchers and offers and other app-only perks.',
              )}
            </NormalText>
            <Spacer size={1} />

            <Subtitle className="block register-group">{__('Email Address')} *</Subtitle>
            <IonItem lines="none" className="input-field-wrapper">
              <IonInput
                placeholder={__('Enter your email address')}
                onIonInput={(e) => {
                  this.handleInput('email', e.target.value);
                }}
                onIonBlur={(e) => this.handleInput('email', e.target.value)}
                clearInput
                required={true}
                type="email"
                pattern="email"
                inputmode="email"
                value={email}
                autocomplete="on"
              ></IonInput>
            </IonItem>
            <FieldError className="field-error" value={__(this.state.formErrors.email)} />
            <Subtitle className="block register-group">{__('Set Password')} *</Subtitle>
            <IonItem lines="none" className="input-field-wrapper">
              <PasswordInput
                placeholder={__('Password')}
                onIonChange={(e) => this.handleInput('password', e.target.value)}
                onIonBlur={(e) => {
                  this.formConfig.password &&
                    this.validateForm({ password: this.formConfig.password })
                }
                }
                value={password}
              />
            </IonItem>
            {this.state.formErrors.password && this.state.formErrors.password == 'Required field' ?
              <FieldError className="field-error" value={__(this.state.formErrors.password)} />
              : null
            }
            <IonItem lines="none" className="input-field-wrapper">
              <PasswordInput
                placeholder={__('Re-enter password')}
                onIonChange={(e) => this.handleInput('repeat_password', e.target.value)}
                onIonBlur={(e) => {
                  if(!this.state.passwordAlertOpen && this.formConfig.repeat_password){
                    this.validateForm({ repeat_password: this.formConfig.repeat_password })
                  }
                }}
                value={repeat_password}
              />
            </IonItem>
            <FieldError className="field-error" value={__(this.state.formErrors.repeat_password)} />

            <Subtitle className="block register-group">{__('Name')} *</Subtitle>
            <IonItem lines="none" className="input-field-wrapper">
              <IonInput
                placeholder={__('First name')}
                onIonInput={(e) => this.handleInput('first_name', e.target.value)}
                required={true}
                type="text"
                pattern="text"
                inputmode="text"
                value={first_name}
              ></IonInput>
            </IonItem>
            <FieldError className="field-error" value={__(this.state.formErrors.first_name)} />

            <IonItem lines="none" className="input-field-wrapper">
              <IonInput
                placeholder={__('Last name')}
                onIonInput={(e) => this.handleInput('last_name', e.target.value)}
                required={true}
                type="text"
                pattern="text"
                inputmode="text"
                value={last_name}
              ></IonInput>
            </IonItem>
            <FieldError className="field-error" value={__(this.state.formErrors.last_name)} />

            <Subtitle className="block register-group">{__('Mobile Number')} *</Subtitle>
            <IonItem lines="none" className="input-field-wrapper">
              <IonInput
                placeholder={__('Mobile Number')}
                onIonInput={(e) => this.handleInput('mobile', e.target.value)}
                required={true}
                type="tel"
                pattern="[0-9]*"
                inputmode="tel"
                maxlength={11}
                value={mobile}
              ></IonInput>
            </IonItem>
            <FieldError className="field-error" value={__(this.state.formErrors.mobile)} />

            <Subtitle className="block register-group">{__('Location')} *</Subtitle>
            <IonItem lines="none" className="input-field-wrapper  dropdown-field">
              <SelectOption
                placeholder="Location"
                display="center"
                group={{ header: true, groupWheel: false, clustered: false }}
                value={location}
                onSet={(e, inst) => {
                  this.handleInput('location', inst.getVal());
                }}
                data={[{ text: '', group: '', value: '' }, ...getConfig().countries_data]}
              ></SelectOption>
            </IonItem>
            <FieldError className="field-error" value={__(this.state.formErrors.location)} />

            <Subtitle className="block register-group">{__('Date Of Birth')} *</Subtitle>
            <IonItem lines="none" className="input-field-wrapper">
              <DatePicker
                className="data-picker-input"
                display="bottom"
                max={yesterday}
                placeholder={__('Date of Birth')}
                setText={__('Done')}
                cancelText={__('Cancel')}
                value={birthday}
                defaultValue={defaultDate}
                onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                dateFormat="dd/mm/yy"
              />
            </IonItem>
            {this.state.formErrors.birthday !== 'user_not_18' && (
              <FieldError className="field-error" value={__(this.state.formErrors.birthday)} />
            )}

            <NormalText>{__('You must be over 18 years old to register')}</NormalText>
            <Subtitle className="block register-group">
              {__('Existing Loyalty Card Number (If applicable)')}
            </Subtitle>
            <IonItem lines="none" className="input-field-wrapper">
              <IonInput
                placeholder={__('e.g. 00038748')}
                onIonInput={(e) =>
                  this.handleInput('existing_loyalty_card_number', e.target.value)
                }
                required={true}
                type="tel"
                pattern="[0-9]*"
                inputmode="tel"
                maxlength={8}
                value={existing_loyalty_card_number}
              ></IonInput>
            </IonItem>
            <NormalText>
              {__('This can be found on the reverse of your Randalls Loyalty Card')}
            </NormalText>
            <Spacer />
            <div className="box-holder box-holder--register">
              <IonList>
                <div lines="none">
                  <div tabIndex="-1"></div>
                  <div className="toggle">
                    <IonLabel>
                      <Subtitle className="">{__('Terms & Conditions')}</Subtitle>
                      <div className="register-terms-wrapper">
                        <IonCheckbox
                          color="primary"
                          checked={accepted_terms_and_conditions}
                          onIonChange={(e) =>
                            this.handleInput('accepted_terms_and_conditions', e.detail.checked)
                          }
                        />
                        <IonLabel className="ion-text-wrap">
                          <NormalText color="primary">
                            {__('By signing up you agree to') + ' '}{' '}
                            <span
                              className="pointer underlined "
                              onClick={() => forwardTo('/terms')}
                            >
                              {__('our terms and conditions')}
                            </span>{' '}
                            {__('and')}{' '}
                            <span
                              className="  pointer underlined"
                              onClick={() => forwardTo('/privacy')}
                            >
                              {__('privacy policy')}
                            </span>
                          </NormalText>
                        </IonLabel>
                      </div>
                    </IonLabel>
                    <FieldError
                      className="field-error"
                      value={__(this.state.formErrors.accepted_terms_and_conditions)}
                    />
                  </div>
                </div>
                <div lines="none">
                  <div tabIndex="-1"></div>
                  <div className="toggle">
                    <IonLabel>
                      <div className="register-terms-wrapper">
                        <IonCheckbox
                          color="primary"
                          checked={is_subscribed}
                          onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)}
                        />
                        <IonLabel className="ion-text-wrap">
                          <NormalText color="primary">
                            {__(
                              "I'd like to receive email updates that contain news, offers and promotions",
                            )}
                          </NormalText>
                        </IonLabel>
                      </div>
                    </IonLabel>
                  </div>
                </div>
              </IonList>
            </div>
            <Spacer size={2} />
            <IonButton
              expand="block"
              color="primary"
              className="uppercase"
              onClick={() => {
                this.registerUser();
              }}
            >
              {__('Continue')}
            </IonButton>
          </div>
        </Layout>
        <IonAlert
          isOpen={isRegisterModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
          header={__('Success')}
          message={__('Register processed. Please check your mail')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
        <IonAlert
          isOpen={this.state.isUserAgeModalOpen}
          onDidDismiss={() => this.setState({ isUserAgeModalOpen: false })}
          header={__('Sorry')}
          message={__('You must be over 18 to register for the Randalls mobile app')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ isUserAgeModalOpen: false }),
            },
          ]}
        />
        <IonAlert
          isOpen={this.state.passwordAlertOpen}
          onDidDismiss={() => this.setState({ passwordAlertOpen: false })}
          message={__(this.state.formErrors.password)}
          buttons={[
            {
              text: __('Continue'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ passwordAlertOpen: false }),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData, protectedReferrer } = state.profile;
  const { navConfig } = state.common;
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    protectedReferrer,
    navConfig,
  };
};

export default connect(stateToProps)(withTranslation(Register));
