import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import PromoBar from '../../components/promoBar';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject, forwardTo, getSingleDeliveryOption } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { NormalText, Subtitle, Title } from '../../components/common';
import HistoryOrderBar from '../../components/historyOrderBar';
import { getNews } from '../../store/actions';
import Modal from '../../components/modal';
import dashboardBanner from '../../assets/images/dashboard-banner.png';
import Slider, { Slide } from '../../components/slider';
const { hasOrdering, hasLoyalty, hasCampaignManager } = getConfig().appType;
const openExternalLink = (url) => window.open(url);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleDelivery: getSingleDeliveryOption(),
      isFullScreenBannerOpened: false,
    };
  }
  getElementPadding = () => {
    const padding = 0;
  };
  componentDidMount() {
    this.props.dispatch(getNews());
  }
  openSliderFullImage = async (data) => {
    this.setState({
      selectedBanner: data,
      isFullScreenBannerOpened: true,
    });
  };

  render() {
    const { __, screenName, vouchers, orderHistory, news } = this.props;
    const { singleDelivery } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
      return false;
    });

    const dashboardContent = getConfig().dashboardConfig;
    return (
      <Layout hasLoyaltyPoints={true} hideSecondToolbar={true} color="transparent" noPadding={true}>
        <div>
          {news.length > 0 ? (
            <Slider
              settings={{

                autoplay: {
                  delay: 3000,
                  disableOnInteraction: false,
                },
              }}
            >
              {news.map((item, i) => (
                <Slide
                  onClick={() => {
                    item.fullscreen_image && this.openSliderFullImage(item);
                  }}
                  className={`slide-${i}`}
                  key={i}
                >
                  <img src={item.banner_image} />
                </Slide>
              ))}
            </Slider>
          )
            :
            <img className="dashboard-banner" src={dashboardBanner} />
          }
        </div>

        <div
          className={`dashboard-promo-bars-wrapper ${orderHistory.length > 0 ? 'padded-top' : ''}`}
        >
          {this.props.notifications.filter(el => !el.is_read).length > 0 &&

            <div className="promo-bar-wrapper">
              <div className="promo-bar-scrim" ></div>

              <div
                className={'promo-bar info'}
                onClick={() => forwardTo('/inbox')}
              >
                <div className="promo-bar-body ellipsis">
                  {__("You have new message")}
                </div>
              </div>
            </div>
          }
          {hasCampaignManager ? <PromoBar type={__('info')} vouchers={voucherRes} /> : null}
          {orderHistory.filter(
            (el) =>
              !el.is_gift &&
              el.status.toLowerCase() !== 'new' &&
              el.status.toLowerCase() !== 'created',
          ).length > 0 && (
              <HistoryOrderBar
                label={__('Order again')}
                handleClick={() => forwardTo('./history', { tab: 'order' })}
              />
            )}
        </div>
        <div className="dashboard-content">
          <div
            className="dashboard-header"
            style={orderHistory.length == 0 ? null : { paddingTop: '10px' }}
          >
            <Subtitle className="uppercase bold letter-spacing-big">
              {__(dashboardContent?.title)}
            </Subtitle>
            <Title>
              {__('Welcome')} {screenName}
            </Title>
            <NormalText>{__(dashboardContent?.description)}</NormalText>
          </div>
          <div className="dashboard-cards-wrapper">
            {dashboardContent?.data?.map((item, i) => {
              return (
                <div
                  key={i}
                  style={{ backgroundImage: `url(${item.image})` }}
                  className="dashboard-card "
                  onClick={() => (item.isLink ? openExternalLink(item.path) : forwardTo(item.path))}
                >
                  <Subtitle key={i}>{__(item.title)}</Subtitle>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          {news.map((item, i) => (
            <img key={i} style={{ height: '0', width: '0' }} src={item.fullscreen_image} />
          ))}
        </div>
        <Modal
          className="banner-fullscreen-image-modal"
          isOpen={this.state.isFullScreenBannerOpened}
        >
          <img
            onClick={() => {
              this.setState({ isFullScreenBannerOpened: false });
            }}
            src={this.state?.selectedBanner?.fullscreen_image}
          />
        </Modal>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { profile, vouchers } = state.profile;
  const { orderHistory } = state.orders;
  const { news } = state.common;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name}`;
  }
  return {
    news,
    screenName,
    vouchers: vouchers || [],
    orderHistory,
    notifications: state.profile.notifications || [],

  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
