import React from 'react';
import { IonModal, IonButton, IonIcon } from '@ionic/react';
import './index.css';

export default class Modal extends React.Component {
  render() {
    const { children, title, action, actionLabel, className, noContent, ...rest } = this.props;
    const { onDidDismiss } = rest;

    return (
      <IonModal className={'modal-classic modal-wrapper' + (className ? ' ' + className : '')} {...rest}>
        <div className="modal-classic-wrapper">
          {onDidDismiss ? (
            <div className="modal-classic-closer" onClick={onDidDismiss}>
              <IonIcon icon="close-outline" />
            </div>
          ) : null}
          {title ? (
            <div className="modal-classic-header">
              <h3>{title}</h3>
            </div>
          ) : null}
          {noContent ? null : <div className="modal-classic-content">{children}</div>}
          {action ? (
            <div className="modal-classic-action">
              <IonButton expand="block" color="secondary" onClick={action}>
                {actionLabel || '--'}
              </IonButton>
            </div>
          ) : null}
        </div>
      </IonModal>
    );
  }
}
