import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {QRCodeCanvas} from 'qrcode.react';
import Basket from '../../lib/basket';
import moment from 'moment';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import {
  getFaq,
  getProfile,
  getRewards,
  getTransactionHistory,
  getVouchers,
  sendVoucherCode,
} from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import './index.css';
import {
  isDefined,
  isEmptyObject,
  isWebConfig,
  validateForm,
  validateProfileData,
} from '../../lib/utils';
import UnlockRewardItems from '../../components/unlockRewardItems';

import {
  NormalText,
  SmallText,
  StrongText,
  Subtitle,
  BigLabel,
  Title,
  FieldError,
  Spacer,
} from '../../components/common';
import { IonButton, IonCol, IonIcon, IonInput, IonItem, IonRow } from '@ionic/react';
import { getConfig } from '../../appConfig';
import ApplyVouchers from '../applyVouchers';
import Modal from '../../components/modal';
import {
  checkmarkCircleOutline,
  chevronDown,
  chevronUp,
  closeCircleOutline,
  informationCircle,
  informationCircleOutline,
} from 'ionicons/icons';
import RedeemGiftVoucher from '../../components/redeemGiftVoucher';
import AddToWallet from '../../components/addToWallet';

const VoucherItem = ({ reward, id, className, __, action }) => {
  return (
    <div className="voucher-wrapper" onClick={action ? () => action(id) : null}>
      <div className="voucher-preview">{__(reward?.name)}</div>
      <div className="voucher-label">
        <StrongText>{__('Loyalty Perk')}</StrongText>
        {reward?.expiry_date && (
          <StrongText>
            {__('Expiry Date:')} {moment(reward?.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}
          </StrongText>
        )}
      </div>
    </div>
  );
};
class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      loyaltyType: '',
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
      refreshActive: true,
      loyaltyFaqModalOpened: false,
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    this.props.dispatch(getFaq());

    if (
      isDefined(this.props.location.state) &&
      isDefined(this.props.location.state.openVoucherModal)
    ) {
      setTimeout(() => {
        this.viewVoucher(this.props.location.state.openVoucherModal);
      }, 500);
    }
    this.props.dispatch(getRewards());
    this.props.dispatch(getTransactionHistory());
    const loyaltyType = getConfig().appType.hasUnlockRewardsOnLoyalty ? 'rewards' : 'points';
    this.setState({ loyaltyType });
    if (this.props.location && this.props.location.state && this.props.location.state.tab) {
      this.setState({ page: this.props.location.state.tab });
    }
  }
  getLabel(item) {
    let label = '';
    if (`${item.business_location_id}` == '-1') {
      label = 'Referral Bonus';
    } else if (`${item.business_location_id}` == '-2') {
      label = 'Sign up Bonus';
    } else if (`${item.business_location_id}` == '-3') {
      label = 'Refunded points';
    } else if (`${item.business_location_id}` == '-4') {
      label = 'Loyalty tier renewed';
    } else if (`${item.business_location_id}` == '-5') {
      label = 'Loyalty tier decreased';
    } else if (`${item.business_location_id}` == '-6') {
      label = 'Loyalty tier increased';
    } else if (`${item.business_location_id}` == '-7') {
      label = 'Loyalty balance transfer';
    } else if (item.stamp_power < 0) {
      label = 'Redeemed Points';
    } else if (item.stamp_power > 0) {
      label = 'Earned Points';
    }
    return label;
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
      //console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
    }
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  drawLoyaltyContent(profile, rewards, __, qr_code) {
    const { loyaltyType } = this.state;
    if (loyaltyType == 'points') {
      return (
        <div className="loyalty-content">
          <Subtitle className="bold">{__('Loyalty Stamp Card')}</Subtitle>
          <div className="content-box box-wrapper small-padding loyalty-content-wrapper">
            <Subtitle className="bold">{__('Loyalty Balance')}</Subtitle>
            <div style={{ textAlign: 'end' }}>
              <Title className="no-margin lowercase points-balance-value">
                {profile.available_balance}
              </Title>
              <SmallText className="lowercase">{__('Points')}</SmallText>
            </div>
          </div>
          <div className="loyalty-content-desc">
            <NormalText className="block small-line-height">
              {__('Collect points every time you scan.')}
            </NormalText>
            <NormalText className="block small-line-height block">
              {__('Earn')}{' '}
              <b>
                {getConfig().api_config.points_value_percentage} {__('points')}
              </b>{' '}
              {__('for every')} {Basket.getCurrency().label} {__('spend')}.
            </NormalText>
          </div>
        </div>
      );
    } else if (loyaltyType === 'rewards') {
      return (
        <div className="loyalty-content">
          <Subtitle className="bold">{__('Available rewards')}</Subtitle>
          <div className="loyalty-rewards-wrapper">
            <UnlockRewardItems
              available_balance={profile.available_balance}
              rewards={rewards}
              qr_code={qr_code}
              __={__}
            />
          </div>

          {/* <div className='loyalty-rewards-wrapper'>
            {rewards.map(item => {
              return (
                <div className='loyalty-reward' style={{backgroundImage:`url(${item.reward.image})`}}>
                  {item.reward.cost>0 && <div className='loyalty-reward-points'><StrongText>{item.reward.cost} Points</StrongText></div>}
                  <div  className='loyalty-reward-name'><StrongText>{item.reward.name}</StrongText></div>
                </div>
              )
            })}
            </div> */}
        </div>
      );
    }
  }

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data, false));
    }
  };

  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };
  onRefreshHandler = (e) => {
    setTimeout(() => {
      this.props.dispatch(getProfile());
      this.props.dispatch(getTransactionHistory());
      this.props.dispatch(getRewards());
      this.props.dispatch(getVouchers());

      e.target.complete();
    }, 2000);
  };
  setRefresher = (e) => {
    if (e.target.scrollTop == 0) {
      this.setState({ refreshActive: true });
    } else if (e.target.scrollTop !== 0 && this.state.refreshActive) {
      this.setState({ refreshActive: false });
    }
  };
  render() {
    const {
      __,
      rewards,
      profile,
      screenName,
      transactionHistory,
      vouchers,
      qr_code,
      faq,
    } = this.props;
    const { voucherCode, selectedVoucher, loyaltyFaqModalOpened } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
    });
    const valid = validateProfileData(profile).isValid;
    const { hasAddToAppleWallet, hasAddToGoogleWallet } = getConfig().flags;
    return (
      <Loading transparent>
        <Layout
          onRefreshHandler={this.onRefreshHandler}
          withRefresher={true}
          color="transparent"
          hideSecondToolbar={true}
          headerTitle={__('Loyalty')}
          noPadding={true}
          pageWithTitle={true}
          headerWithTitle={true}
          refreshActive={this.state.refreshActive}
        >
          <div className="scrollable-y refresher-container" onScroll={(e) => this.setRefresher(e)}>
            <div className="loyalty-header">
              <IonIcon
                onClick={() => {
                  this.setState({ loyaltyFaqModalOpened: true });
                }}
                color="secondary"
                className="loyalty-header-information-button"
                icon={informationCircleOutline}
              />
              <div className="loyalty-header-name">
                <Title className="loyalty-name bold uppercase">{screenName}</Title>
                <Spacer size={1} />
                <div className="qr-code">
                  {isDefined(profile.qr_code) ? (
                    <div className="qr-holder">
                      <QRCodeCanvas value={profile.qr_code} size={isWebConfig() ? 150 : 200} />
                    </div>
                  ) : (
                    <div>
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </div>
                {isDefined(profile.qr_code) &&
                profile.qr_code !== '' &&
                (hasAddToAppleWallet || hasAddToGoogleWallet) ? (
                  <AddToWallet />
                ) : null}
                <Spacer size={1} />
                <NormalText className="uppercase">{`${__('Earn')} ${
                  profile.current_tier?.current_earning_rate
                }% ${__('loyalty on every £ spent')}`}</NormalText>
                {!profile.is_staff && (
                  <>
                    {parseInt(profile.current_tier?.spend_to_next_tier) < 0 && (
                      <SmallText>
                        {`${__('Spend another')} ${Basket.formatPrice(
                          Math.abs(profile.current_tier?.spend_to_next_tier),
                        )} ${__('before')} ${moment(profile.current_tier?.renews_on_date).format(
                          'DD/MM/yyyy',
                        )} ${__('to maintain')}`}
                      </SmallText>
                    )}
                    {parseInt(profile.current_tier?.spend_to_next_tier) > 0 && (
                      <SmallText>
                        {`${__('Spend another')} ${Basket.formatPrice(
                          profile.current_tier?.spend_to_next_tier,
                        )} ${__('before')} ${moment(profile.current_tier?.renews_on_date).format(
                          'DD/MM/yyyy',
                        )} ${__('to move up to')} ${profile.current_tier?.next_tier_name}`}
                      </SmallText>
                    )}
                    {parseInt(profile.current_tier?.spend_to_next_tier) == 0 && (
                      <SmallText>{`${__('You will be a')} ${
                        profile.current_tier?.next_tier_name
                      } ${__('member from')} ${moment(profile.current_tier?.renews_on_date).format(
                        'DD/MM/yyyy',
                      )}`}</SmallText>
                    )}
                  </>
                )}
              </div>
              <Spacer size={1} />
              <div className="loyalty-header-content">
                <div>
                  <Title className="bold uppercase">{__('POINTS BALANCE')}</Title>
                  <Subtitle className="bold">{profile.available_balance}</Subtitle>
                  <SmallText>{`${__('worth')} ${Basket.formatPrice(
                    Basket.calculatePointsAppliedPrice(profile.available_balance),
                    true,
                  )}`}</SmallText>
                </div>
                <div>
                  <Title className="bold uppercase">{__('SPEND THIS PERIOD')}</Title>
                  <Subtitle className="bold">
                    {Basket.getCurrency().label}
                    {profile.current_tier?.total_earned_this_period}
                  </Subtitle>
                  <SmallText>{`${__('since')} ${moment(profile.current_tier?.date_joined).format(
                    'DD/MM/yyyy',
                  )}`}</SmallText>
                </div>
              </div>
            </div>
            <div className="loyalty-validation-wrapper">
              {valid ? (
                <>
                  <NormalText>{__('Account Verified')}</NormalText>
                  <IonIcon color="success" icon={checkmarkCircleOutline} />
                </>
              ) : (
                <>
                  <NormalText>{__('Account Not Verified')}</NormalText>
                  <IonIcon color="danger" icon={closeCircleOutline} />
                </>
              )}
            </div>
            <div className="loyalty-vouchers-wrapper">
              <div className="redeem-voucher-field-wrapper">
                <div className="input-field-container">
                  <Subtitle>{__('Redeem Voucher Code')}</Subtitle>
                  <IonItem lines="none" className="input-field-wrapper">
                    <IonInput
                      placeholder={__('Voucher Code')}
                      onIonInput={(e) => this.handlerOfVoucherCode(e)}
                      value={voucherCode}
                    ></IonInput>
                  </IonItem>
                </div>
                <IonButton
                  disabled={voucherCode === ''}
                  color="primary"
                  className="uppercase"
                  expand="block"
                  onClick={this.sendCode}
                >
                  {__('Submit')}
                </IonButton>
              </div>
              <FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
              <div>
                {voucherRes.length > 0 && <Subtitle>{__('Your Vouchers')}</Subtitle>}
                {voucherRes &&
                  voucherRes.map((voucher, index) => {
                    return (
                      <VoucherItem
                        key={'vc-' + index}
                        {...voucher}
                        __={__}
                        action={this.viewVoucher}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="loyalty-history">
              {transactionHistory.length > 0 && <Subtitle>{__('Latest Transactions')}</Subtitle>}
              <div className="transactions-wrapper box-wrapper">
                {transactionHistory.map((el) => {
                  return (
                    <div className="content-box small-padding ">
                      <IonRow>
                        <IonCol
                          size="8"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <NormalText>{el.location_name}</NormalText>
                          <Subtitle className="block ">{__(this.getLabel(el))}</Subtitle>
                          <NormalText>
                            {Basket.getDate(el.transaction_date).format('DD MMM YYYY h:mm a')}
                          </NormalText>{' '}
                        </IonCol>
                        <IonCol
                          ize="4"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <div className="transaction-points-wrapper">
                            {el.business_location_id == '-4' ||
                            el.business_location_id == '-5' ||
                            el.business_location_id == '-6' ? (
                              <>
                                {el.business_location_id == '-4' && <Title>{el.new_rate}</Title>}
                                {el.business_location_id == '-5' && (
                                  <div>
                                    <IonIcon color="secondary" icon={chevronDown} />
                                    <Title className="secondary-color">{el.new_rate}</Title>
                                  </div>
                                )}
                                {el.business_location_id == '-6' && (
                                  <div>
                                    <IonIcon color="success" icon={chevronUp} />
                                    <Title className="success-color">{el.new_rate}</Title>
                                  </div>
                                )}
                                <StrongText>{__('tier')}</StrongText>
                              </>
                            ) : (
                              <>
                                <Title className={el.stamp_power < 0 ? ' ' : ' success-color'}>
                                  {el.stamp_power < 0 ? '-' : '+'}
                                  {Math.abs(el.stamp_power)}
                                </Title>
                                <NormalText className="bold">{__('points')}</NormalText>
                              </>
                            )}
                          </div>
                        </IonCol>
                      </IonRow>
                      {/* <IonRow>
                        <IonCol>
                          
                        </IonCol>
                        <IonCol style={{ textAlign: 'end' }}>
                          <NormalText >
                            {' '}
                            {Basket.getDate(el.transaction_date).format('DD MMM YYYY h:mm a')}
                          </NormalText>
                        </IonCol>
                      </IonRow> */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Layout>
        <Modal
          className="loyalty-faq-modal"
          isOpen={loyaltyFaqModalOpened}
          onDidDismiss={() => {
            this.setState({ loyaltyFaqModalOpened: false });
          }}
        >
          <div className="lefted">
            <Title>{__('Faq & Support')}</Title>
            <div dangerouslySetInnerHTML={{ __html: faq }}></div>
          </div>{' '}
        </Modal>

        <Modal
          className="voucher-modal"
          isOpen={selectedVoucher !== null}
          onDidDismiss={() => {
            this.setState({ selectedVoucher: null });
            this.props.history.replace({ state: {} });
          }}
        >
          {selectedVoucher && selectedVoucher.reward ? (
            <div style={{ textAlign: 'center' }}>
              <Title>{__('Voucher Info')}</Title>
              <VoucherItem {...selectedVoucher} __={__} />
              <Spacer />
              <div className="voucher-modal-content">
                {selectedVoucher.reward.name ? (
                  <>
                    <Title className="centered uppercase bold no-margin-bottom">
                      {selectedVoucher.reward.name}
                    </Title>
                  </>
                ) : null}
                {selectedVoucher.reward.small_print ? (
                  <div>
                    <NormalText>{selectedVoucher.reward.small_print}</NormalText>
                  </div>
                ) : null}
                <hr />
                {selectedVoucher.reward.expiry_date ? (
                  <div>
                    <StrongText>
                      {__('Expires:')}{' '}
                      {moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}
                    </StrongText>
                  </div>
                ) : null}
                <Spacer size={1} />
                <>
                  {isDefined(qr_code) ? (
                    <div className="qr-holder">
                      <QRCodeCanvas value={qr_code} size={150} />
                    </div>
                  ) : (
                    <div className="noQrCode">
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </>
                {selectedVoucher.reward.description ? (
                  <div>
                    <SmallText className="block small-line-height">
                      {__(selectedVoucher.reward.description)}
                    </SmallText>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { rewards } = state.restaurants;
  const { history } = state.orders;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name} ${profile.last_name}`;
  }
  return {
    profile,
    rewards: rewards || [],
    screenName,
    transactionHistory: history || [],
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
    faq: state.common.faq || null,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Loyalty)));
